import React,{useState, useEffect} from 'react'
import { Box, Container, Divider, Grid, TextField, Typography, Stack, Skeleton } from '@mui/material'
import { NavLink } from 'react-router-dom'

import BreadcrumbsNav from '../ReusableComponents/BreadcrumbsNav'
import Heading from '../ReusableComponents/Heading'

import dayjs from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';


const DeadlineCalendar = () => {
    const todayDate = new Date();
    console.log("todayDate", todayDate)

    const [value, setValue] = useState('Fri Sep 02 2022 09:56:57 GMT+0530 (India Standard Time)');

    useEffect(() => {
        document.title = "Deadline Calendar    - fundsforNGOs Premium"
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <BreadcrumbsNav pageName="Deadline Calendar" />
            <Box className='main_section' sx={{ bgcolor: 'background.default' }}>
                <Container maxWidth='xl'>
                    <Heading heading="Deadline Calendar" />
                    <Grid container mt={4} columnSpacing={3}>

                        <Grid item xs={12} md={6} lg={5} xl={5}>
                            <Box position={{ xs: 'relative', md: 'sticky' }} top={{ xs: '0', md: '25px' }} sx={{ p: 1, bgcolor: 'background.paper', }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <StaticDatePicker
                                        sx={{
                                            bgcolor: 'primary.main', color: 'white', width: '100%',
                                        }}
                                        //orientation="portrait"
                                        orientation="landscape"
                                        //showToolbar={false}
                                        displayStaticWrapperAs="mobile"
                                        openTo="day"
                                        value={value}
                                        minDate={todayDate.getFullYear() - 15 + ""}
                                        maxDate={todayDate.getFullYear() + 15 + ""}
                                        views={["year", "month", "day"]}

                                        onChange={(newValue) => {
                                            setValue(newValue);
                                        }}
                                        onYearChange={(newValue) => {
                                            setValue(newValue);
                                        }}
                                        onMonthChange={(newValue) => {
                                            debugger;
                                            setValue(newValue);
                                        }}
                                        onDateChange={(newValue) => {
                                            debugger;
                                            setValue(newValue);
                                        }}

                                        renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                                        componentsProps={{
                                            actionBar: {
                                                actions: ['today'],
                                            },
                                        }}

                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6} lg={7} xl={7}>
                            <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
                                <Typography variant='subtitle1' sx={{ pb: 1, }} color='text.active'>August,01,2022</Typography>
                                <Divider />
                                <Typography fontWeight='bold' sx={{ mt: 1.2, }} color='text.secondary'>35 Open opportunities as on August,01,2022</Typography>

                                <Box sx={{ bgcolor: 'background.default', mt: 2, }}>
                                    <Grid container columnSpacing={0} p={0.5}  >
                                        <Grid item xs={4} sm={2} >
                                            <Skeleton variant="rectangular" width='100%' height={120} />

                                        </Grid>
                                        <Grid item xs={8} sm={10} >
                                            <Stack ml={2} mt={1.5}>
                                                <Skeleton width='80%' sx={{ bgcolor: 'primary.light' }} animation="wave" />
                                                <Skeleton width='50%' />
                                                <Skeleton width='60%' />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box sx={{ bgcolor: 'background.default', mt: 2, }}>
                                    <Grid container columnSpacing={0} p={0.5} border='1px solid lightgray'  >
                                        <Grid item xs={4} sm={2} >
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: "100%",
                                                    maxHeight: { xs: "100%", md: "100%" },
                                                    maxWidth: { xs: "100%", md: "100%" },
                                                }}
                                                alt="Logo"
                                                src='https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/ZLVgDTKYXy478mDRPkuNSaFYhFu3WC7sJPKERH0N.jpg'
                                            />

                                        </Grid>
                                        <Grid item xs={8} sm={10} >
                                            <Stack ml={2} mt={1.5}>
                                                <NavLink to='/'>
                                                    <Typography color='text.active' fontWeight='bold' variant='subtitle'>US: Humane Treatment of Animals Grant Program</Typography>
                                                </NavLink>
                                                <Typography color='text.primary' mt={1}>
                                                    <strong>Post Date:</strong>  June 16, 2022

                                                </Typography>
                                                <Typography color='text.primary' mt={0.5}>
                                                    <strong >  Deadline Date:</strong>   August 01, 2022
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>



                            </Box>

                        </Grid>


                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default DeadlineCalendar