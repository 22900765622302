import React, { useState,useEffect } from 'react'
import {
    Box, Container, Grid,
    Typography,
    FormControl,
    InputLabel, OutlinedInput, InputAdornment, IconButton, Button,
} from '@mui/material';
import BreadcrumbsNav from '../ReusableComponents/BreadcrumbsNav';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ForgetPass = () => {

    const [currentPass, setCurrentPass] = useState()
    const [newPass, setNewPass] = useState()
    const [confirmPass, setConfirmPass] = useState()

    const [values, setValues] = useState(false);

    useEffect(() => {
        document.title = "Reset Password  -  fundsforNGOs Premium"
        window.scrollTo(0, 0);
    }, [])

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <BreadcrumbsNav pageName="Change Password" />
            <Box className='main_section' sx={{ bgcolor: 'background.default' }}>
                <Container maxWidth='xl'>
                    <Grid container columnSpacing={4}>
                        <Grid item xs={12} md={12} xl={5} m='auto'>
                            <Box p={5} sx={{ bgcolor: 'background.paper' }} >
                                <Typography color='text.active' variant='subtitle1'>Change Password</Typography>
                                <Grid container columnSpacing={3}>
                                    <Grid item xs={12} my={5}  >
                                        <FormControl fullWidth variant="outlined" >
                                            <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={currentPass}
                                                onChange={(e) => setCurrentPass(e.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Current Password"
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6}  >
                                        <FormControl fullWidth variant="outlined" >
                                            <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={newPass}
                                                onChange={(e) => setNewPass(e.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Current Password"
                                            />
                                        </FormControl>

                                    </Grid>

                                    <Grid item xs={12} md={6}  >
                                        <FormControl fullWidth variant="outlined" >
                                            <InputLabel htmlFor="outlined-adornment-password">Re-type New Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={confirmPass}
                                                onChange={(e) => setConfirmPass(e.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Re-type New Password"
                                            />
                                        </FormControl>

                                    </Grid>

                                    <Grid item xs={12} md={12} mt={4}  >
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            disableElevation
                                            sx={{ m: 'auto', px: 4, py: 1, }}

                                        >
                                            Save
                                        </Button>

                                    </Grid>

                                </Grid>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box >


        </>
    )
}

export default ForgetPass;