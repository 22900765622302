import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Container,
    Avatar,
    Tooltip,
    MenuItem,
    ListItemIcon,
    Divider,
    Switch,
    FormControlLabel, Drawer, Stack, Fab,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import Logo from "../../images/logo.png"
import { NavLink } from 'react-router-dom';
//componets
import DesktopMenu from './DesktopMenu';
import ScrollTop from './ScrollTop';
import HideOnScroll from './HideOnScroll';

//import MobileMenu from './MobileMenu';
import { MenuItems } from './MobileMenu';

//ThemeContext
import ThemeContext from "./Context/Themecontext";
// icons
import MenuIcon from '@mui/icons-material/Menu';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LockResetIcon from '@mui/icons-material/LockReset';
import PaymentIcon from '@mui/icons-material/Payment';
import HelpIcon from '@mui/icons-material/Help';
import Logout from '@mui/icons-material/Logout';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';




const settings = [
    { id: 0, icon: <FavoriteBorderIcon fontSize="small" />, title: 'My Favorites', link: '/favorites' },
    { id: 1, icon: <AssignmentIndIcon fontSize="small" />, title: 'Update Profile', link: '/mydetails' },
    { id: 2, icon: <ReceiptIcon fontSize="small" />, title: 'My Invoices', link: '/myinvoices' },
    { id: 3, icon: <LockResetIcon fontSize="small" />, title: 'Change Password', link: '/resetpassword' },
    { id: 4, icon: <PaymentIcon fontSize="small" />, title: 'Update Payment Details', link: '/mypaymentdetails' },
    { id: 5, icon: <HelpIcon fontSize="small" />, title: 'Help', link: '/support' },
];


const menu = [
    {
        id: 0,
        title: 'Home',
        to: '/',
        items: []
    },
    {
        title: 'Grants',
        to: '#',
        id: 1,
        icon: <ArrowDropDownIcon color='text.primary' />,
        items: [
            {
                title: 'Funds for NGOs',
                to: '#',
                pId: 1,
                id: 12,
                icon: <ArrowRightIcon sx={{ marginLeft: 'auto' }} />,
                items: [
                    {
                        title: 'Latest Grants',
                        to: '/opportunity'
                    },
                    {
                        title: 'Upcoming Deadlines',
                        to: '/upcomingdeadlines'
                    },
                    {
                        title: 'Deadline Calendar',
                        to: '/calendar'
                    },
                    {
                        title: 'Deadline Free Grants',
                        to: '/deadlinefreegrants'
                    },
                    {
                        title: 'Grants Map',
                        to: '/maps'
                    },
                    {
                        title: 'Advanced Search',
                        to: '/advancesearch'
                    },
                ]
            },
            {
                title: 'Funds for Companies',
                to: '/companies-opportunities'
            },
            {
                title: 'Funds for Media',
                to: '/media-opportunities'
            },
            {
                title: 'Funds for Arts',
                to: '/arts-opportunities'
            },
        ]
    },
    {
        title: 'Resources',
        to: '#',
        id: 2,
        icon: <ArrowDropDownIcon color='text.primary' />,
        items: [
            {
                title: 'Sample Proposals',
                to: '#',
                pId: 2,
                id: 13,
                icon: <ArrowRightIcon sx={{ marginLeft: 'auto' }} />,
                items: [
                    {
                        title: 'Sample Proposals',
                        to: '/sampleproposal'
                    },
                    {
                        title: ' Your Download List',
                        to: '/sampleproposal/downloadlist/mydownloads'
                    },
                ]
            },
            {
                title: 'eBooks',
                to: '/download'
            },
            {
                title: 'Training Videos',
                to: '/trainingvideos'
            },
            {
                title: 'Articles',
                to: '/articles'
            },
            {
                title: 'Courses ',
                to: '/courses'
            },
        ]
    },
    {
        id: 3,
        title: 'Donor Profiles ',
        to: '/donorsearch',
        items: []
    },
    {
        id: 4,
        title: 'Alerts ',
        to: '#',
        icon: <ArrowDropDownIcon color='text.primary' />,
        items: [
            {
                title: 'Funding Alerts ',
                to: '/specific-funding-alerts'
            },
            {
                title: 'Job Alerts',
                to: '/specific-job-alerts'
            },
        ]
    },
    {
        id: 5,
        title: 'Jobs ',
        to: '/Job',
        items: []
    },
    {
        id: 6,
        title: 'Donor News',
        to: '/news',
        items: []
    },

]


const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));



ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const Header = (props) => {
    //theme 
    const thememode = useContext(ThemeContext);
    const [darkTheme, setDarkTheme] = useState(false);
    const [theme, setTheme] = useState(false);
    const { onChange } = thememode;

    const [anchorElUser, setAnchorElUser] = useState(null);

    //mobile menu 
    const [mobileMenu, setMobileMenu] = useState(false);


    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(false);
    };



    const closeMenu = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setMobileMenu(false);
    };

    useEffect(() => {
        const themeType = localStorage.getItem("dark") || "light";
        if (themeType !== "dark") { setTheme(false); onChange(false) }
        else { onChange(true); setTheme(true); }
    }, [theme]);

    //handleTheme 
    const handleTheme = () => {
        setDarkTheme(darkTheme === false ? true : false)
        localStorage.setItem("dark", theme ? "light" : "dark");
        setTheme(!theme);
        onChange(theme)
    }


    return (
        <>
            <HideOnScroll {...props}>
                <AppBar id="back-to-top-anchor" position="static"
                    sx={{
                        py: 0.4,
                        bgcolor: 'background.paper'

                    }} >

                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                href="/"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', lg: 'flex' },
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                <NavLink to='/'>
                                    <img src={Logo} alt="" />
                                </NavLink>

                            </Typography>

                            {/* mobile Menu */}
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={() => setMobileMenu(true)}
                                    color="inherit"
                                >
                                    <MenuIcon sx={{ color: 'text.active' }} />
                                </IconButton>
                                {/* mobile sidebar */}
                                <Drawer
                                    anchor="left" open={mobileMenu} onClose={closeMenu}>
                                    <Box sx={{
                                        width: 250,
                                        overflow: "auto",
                                        height: "100%",
                                        position: "relative",
                                    }}
                                    >
                                        <Stack position='relative' py={1} px={2} >
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: "100%",
                                                    maxHeight: { xs: "80%", md: "80%" },
                                                    maxWidth: { xs: "80%", md: "80%" },
                                                }}
                                                alt="Logo"
                                                src={Logo}
                                            />
                                            <IconButton sx={{ position: 'absolute', right: 0 }} onClick={closeMenu}>
                                                <CloseIcon />
                                            </IconButton>

                                        </Stack>
                                        <Divider />

                                        {menu.map((item, key) => <MenuItems key={key} item={item} closeMenu={closeMenu} />)}
                                    </Box>

                                </Drawer>
                            </Box>
                            {/* mobile Menu close */}

                            {/* mobile Logo */}
                            <Typography
                                variant="h5"
                                noWrap
                                component="a"
                                href=""
                                sx={{
                                    mr: 2,
                                    display: { xs: 'flex', lg: 'none' },
                                    flexGrow: 1,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        height: "100%",
                                        maxHeight: { xs: "100%", md: "100%" },
                                        maxWidth: { xs: "100%", md: "100%" },
                                    }}
                                    alt="Logo"
                                    src={Logo}
                                />

                            </Typography>
                            {/* close Logo */}

                            <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' }, justifyContent: "end", }}>
                                <DesktopMenu menu={menu} />
                            </Box>


                            {/* user Profile  */}
                            <Box sx={{ flexGrow: 0, ml: 2, }}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar alt="Remy Sharp" sx={{ bgcolor: 'background.primary' }} src="/static/images/avatar/2.jpg" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}

                                >
                                    {settings.map((setting) => (
                                        <NavLink to={setting.link} key={setting.id} >
                                            <MenuItem onClick={handleCloseUserMenu}>

                                                <ListItemIcon>
                                                    {setting.icon}
                                                </ListItemIcon>
                                                <Typography textAlign="center" color='text.secondary'>{setting.title}</Typography>

                                            </MenuItem>
                                        </NavLink>
                                    ))}
                                    <Divider />
                                    <MenuItem>

                                        <Typography textAlign="center"> Theme</Typography>
                                        <FormControlLabel sx={{ ml: "auto" }}
                                            control={<MaterialUISwitch onClick={handleTheme} checked={theme ? true : false} />}
                                            label={theme ? "dark" : "light"}
                                        />
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem>
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>

                                </Menu>


                            </Box>
                        </Toolbar>
                    </Container>

                </AppBar>
            </HideOnScroll>

            <ScrollTop {...props}>
                <Fab color='primary' size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </>
    )
}

export default Header