import React, { useState,useEffect } from 'react'
import {
    Stack, Box, Container, Grid,
    Typography,
    FormControl, Divider,
    InputLabel, OutlinedInput, InputAdornment, IconButton, TextField, Button, Tooltip, Link,
} from '@mui/material';

//icon
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';



const Login = () => {
    const [pass, setPass] = useState();
    const [eyeIcon, setEyeIcon] = useState(false);

    const [show, setShow] = useState(0);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const HandleClickForget = () => {
        setShow(show === 2 ? 0 : 2)
    }

    const HandleSignInOtp = () => {
        setShow(show === 1 ? 0 : 1)
    }

    useEffect(() => {
        document.title = "Login   - fundsforNGOs Premium"
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <Box className='main_section' sx={{ bgcolor: 'background.default' }}>
                <Container maxWidth='xl'>
                    <Grid container columnSpacing={4}>
                        <Grid item xs={12} md={7} lg={5} xl={4} m='auto'>
                            <Box p={{ md: 5, xs: 2 }} sx={{ bgcolor: 'background.paper', boxShadow: 2, }} >
                                {/* login section */}
                                {show === 0 ?
                                    <>
                                        <Typography variant='h2' fontWeight='bold' textAlign='center' color='text.active' >
                                            Premium Membership<br /> Login
                                        </Typography>

                                        <TextField
                                            sx={{ my: 4, }}
                                            fullWidth
                                            required
                                            id="outlined-required"
                                            label="Email Address"
                                            type='email'
                                        />

                                        <FormControl fullWidth variant="outlined" >
                                            <InputLabel htmlFor="password"> Password *</InputLabel>
                                            <OutlinedInput
                                                id="password"

                                                type={eyeIcon ? 'text' : 'password'}
                                                value={pass}
                                                onChange={(e) => setPass(e.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setEyeIcon(!eyeIcon)}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {eyeIcon ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label=" Password *"

                                            />
                                        </FormControl>
                                        <Typography onClick={HandleClickForget} variant='h6' color='text.active' mt={1} textAlign='right' fontWeight='bold' >
                                            Forgot your password?
                                        </Typography>

                                        <Button color='primary' variant='contained' disableElevation sx={{ width: '100%', mt: 4, py: 1, mb: 3, }}>  Sign In</Button>

                                        <Tooltip placement="top" title=" Sign in using One-Time Password (OTP)" arrow>
                                            <Divider><Typography onClick={HandleSignInOtp} color='text.primary' > Sign in using (OTP) </Typography></Divider>
                                        </Tooltip>

                                        <Stack direction={{ xs: 'grid', sm: 'row' }} mt={4}>
                                            <Typography color='text.primary'>Not a member?
                                                <Link target="_blank" rel="noopener noreferrer" href="https://fundsforngospremium.com/PlanListnerWeb.ashx?PlanCode=USD99ffn">
                                                    Sign up
                                                </Link>
                                            </Typography>
                                            <Typography ml='auto'>
                                                <Link target="_blank" rel="noopener noreferrer" href="https://support.fundsforngos.org/hc/en-us/articles/360038959633-Why-I-cannot-sign-in-">Why I cannot sign in?</Link>
                                            </Typography>
                                        </Stack>
                                    </>
                                    : ""}

                                {/* Sign in via    */}
                                {show === 1 ?
                                    <>
                                        <Stack direction='row' mb={3} alignItems='center'>
                                            <Typography variant='subtitle1' fontWeight='bold' textAlign='center' color='text.active' >
                                                Sign in via (OTP)
                                            </Typography>

                                            <Button onClick={HandleSignInOtp} variant="text" startIcon={<ChevronLeftIcon />} sx={{ ml: 'auto' }}>
                                                Back
                                            </Button>
                                        </Stack>

                                        <Typography color='text.primary'>Please submit your registered email address associated with your membership account and a secure code will be sent to your email inbox.</Typography>

                                        <TextField
                                            sx={{ my: 4, }}
                                            fullWidth
                                            required
                                            id="outlined-required"
                                            label="Enter registered email address"
                                            type='email'

                                        />

                                        <Button color='primary' variant='contained' disableElevation sx={{ width: '100%', py: 1, mb: 3, }}>  Request OTP</Button>

                                        <Link
                                            target="_blank"
                                            textAlign='center'
                                            display='block'
                                            rel="noopener noreferrer"
                                            href="https://support.fundsforngos.org/hc/en-us/articles/360038959633-Why-I-cannot-sign-in-">
                                            Why I cannot sign in?
                                        </Link>

                                    </>
                                    : ""}

                                {/* OPT section      */}
                                {show === 2 ?
                                    <>
                                        <Stack direction='row' mt={4} alignItems='center'>
                                            <Typography variant='subtitle1' fontWeight='bold' textAlign='center' color='text.active' >
                                                Forgot Password?
                                            </Typography>

                                            <Button onClick={HandleClickForget} variant="text" startIcon={<ChevronLeftIcon />} sx={{ ml: 'auto' }}>
                                                Back
                                            </Button>
                                        </Stack>

                                        <TextField
                                            sx={{ my: 4, }}
                                            fullWidth

                                            required
                                            id="outlined-required"
                                            label="Enter registered email address"
                                            type='email'
                                        />

                                        <Button color='primary' variant='contained' disableElevation sx={{ width: '100%', py: 1, mb: 3, }}> Submit</Button>

                                    </>
                                    : ""}



                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default Login;