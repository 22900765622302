import React, { useState, useRef, useEffect } from "react";

import { Button, ClickAwayListener, Paper, Popper, MenuItem, MenuList, Stack, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom"


export default function DesktopMenu({ menu }) {


    const location = useLocation();


    const [open, setOpen] = useState(false);
    const [supOpen, setSubOpen] = useState(false);

    const anchorRef = useRef(null);

    const anchorRef1 = useRef(null);

    const handleClose = (event) => {

        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleClose1 = (event) => {
       
        if (anchorRef1.current && anchorRef1.current.contains(event.target)) {
            return;
        }
        setSubOpen(false)
        setOpen(false);
    };



    function handleListKeyDown(event) {

        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === "Escape") {
            setOpen(false);

        }
    }



    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    const prevOpen1 = useRef(supOpen);
    useEffect(() => {
        if (prevOpen1.current === true && open === false) {
            anchorRef1.current.focus();
        }
        prevOpen1.current = open;
    }, [supOpen]);

    //console.clear("")

    return (
        <Stack direction="row" alignItems='center' spacing={0.8}>
            {menu.map((val, inx) => {
                return (
                    <div key={inx}
                        onMouseEnter={() => setOpen(val.id)}
                        onMouseLeave={() => setOpen(false)}
                        //onClick={() => setOpen(val.id)}
                        style={{ position: "relative" }}
                    >
                        <NavLink to={val.to}>
                            <Button
                                id="composition-button"
                                aria-controls={open === val.id ? "composition-menu" : undefined}
                                aria-expanded={open === val.id ? "true" : undefined}
                                aria-haspopup="true"
                                ref={anchorRef}
                            >

                                <Typography display='flex' alignItems='center' variant="h6" fontWeight='600'
                                    color={() => {
                                        var active = 'text.secondary';
                                        if (location.pathname === val.to) {
                                            active = 'text.active'
                                        }
                                        else {
                                            if (!val.items.length) {
                                                active = 'text.secondary'
                                            }
                                            else {
                                                val.items.map(val1 => {
                                                    if (active != 'text.active') {
                                                        if (val1.to === location.pathname) {
                                                            active = 'text.active'
                                                        }
                                                        else {
                                                            if (val1.items) {
                                                                val1.items.map(val2 => {
                                                                    if (active != 'text.active') {
                                                                        if (val2.to.split('/').join('/') === location.pathname) {
                                                                            active = 'text.active'
                                                                        }
                                                                        else {
                                                                            active = 'text.secondary'
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                            else {
                                                                active = 'text.secondary'
                                                            }
                                                        }
                                                    }
                                                })
                                            }

                                        }
                                        return active
                                    }
                                    }
                                >
                                    {val.title}  {val.icon ? val.icon : ""}
                                </Typography>
                            </Button>
                        </NavLink>

                        <Popper
                            open={open === val.id ? true : false}
                            sx={{ position: 'absolute !important', top: '100% !important', zIndex: '999', }}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                        >

                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open === val.id ? true : false}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        {val.items ?
                                            val.items.map((val1, inx1) => {
                                                return (
                                                    <div key={inx1} style={{ position: "relative" }}
                                                        onMouseEnter={() => setSubOpen(val1.id)}
                                                        onMouseLeave={() => setSubOpen(false)}
                                                        //onClick={() => setSubOpen(val1.id)}
                                                        aria-haspopup="true"
                                                    >
                                                        <NavLink to={val1.to} style={{ width: '100%' }} >
                                                            <MenuItem
                                                                id="composition-button"
                                                                onClick={handleClose}
                                                                aria-controls={supOpen ? "composition-menu" : undefined}
                                                                aria-expanded={supOpen ? "true" : undefined}
                                                                aria-haspopup="true"
                                                                ref={anchorRef1}
                                                                sx={{ minWidth: '220px' }}
                                                            >

                                                                <Typography display='flex' color='text.primary' width='100%' >
                                                                    {val1.title}    {val1.icon ? val1.icon : ""}
                                                                </Typography>


                                                            </MenuItem>
                                                        </NavLink>

                                                        <Popper
                                                            open={supOpen === val1.id ? true : false}
                                                            sx={{ position: 'absolute !important', top: '0% !important', left: "100% !important", zIndex: 999, }}
                                                            anchorEl={anchorRef1.current}
                                                            role={undefined}
                                                            placement="right-start"
                                                            transition
                                                            disablePortal
                                                        >
                                                            <Paper>

                                                                <ClickAwayListener onClickAway={handleClose1}>
                                                                    <MenuList
                                                                        autoFocusItem={supOpen === val1.id ? true : false}
                                                                        id="composition-menu1"
                                                                        aria-labelledby="composition-button1"
                                                                        onKeyDown={handleListKeyDown}

                                                                    >
                                                                        {val1.items ?
                                                                            val1.items.map((val2, inx2) => {
                                                                                return (
                                                                                    <NavLink to={val2.to} key={inx2} >
                                                                                        <MenuItem sx={{ minWidth: '220px' }}  onClick={handleClose1}  >
                                                                                            <Typography color='text.primary'>
                                                                                                {val2.title}
                                                                                            </Typography>
                                                                                        </MenuItem>
                                                                                    </NavLink>
                                                                                )
                                                                            }) : ""}

                                                                    </MenuList>
                                                                </ClickAwayListener>


                                                            </Paper>
                                                        </Popper>

                                                    </div>
                                                )
                                            })
                                            : ""}


                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>

                        </Popper>
                    </div>
                )
            })}

        </Stack >
    );
}
