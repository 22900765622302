import React from 'react'
import Heading from './Heading';
import Slider from 'react-slick';
import { NavLink } from "react-router-dom"
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Box, Card, CardMedia, CardContent, Typography, CardActions, Divider, Skeleton } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


const SlickSlider = ({ heading, items }) => {

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };



  return (
    <>
      <Grid item xs={12}>
        <Heading heading={heading} />
        {items ?
          <Slider {...settings} className="details-slider px-3 mt-3 mt-md-4">
            {items.map((val, inx) => {
              return (
               
                  <Box sx={{ mt: 4, }} key={inx} >
                    <Card sx={{ p: 1, mx: 0.8, }} >
                      <NavLink to='/'>
                        <CardMedia
                          component="img"
                          sx={{ borderRadius: 1, }}
                          image={val.image}
                          alt={val.title}
                        />
                      </NavLink>
                      <CardContent sx={{ py: 1, px: 1, }}>
                        <NavLink to='/' style={{ textDecoration: "none", }}>
                          <Typography color="text.active" sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                            height: '65px'

                          }}>
                            {val.title}
                          </Typography>
                        </NavLink>
                      </CardContent>
                      <Divider />
                      <CardActions disableSpacing>
                        <Typography display='flex' variant='h6'>
                          <strong style={{ display: 'flex', marginRight: '2px' }}>
                            <CalendarMonthIcon sx={{ fontSize: '18px', marginRight: '2px' }} /> Post Date:</strong>
                          {val.lastUpdated}</Typography>

                      </CardActions>

                    </Card>
                  </Box>

                  
               
              )
            })}

          </Slider>
          : ""
        }

      </Grid>

    </>
  )
}

export default SlickSlider;

{/* <Box sx={{ mt: 4, mb:2,}} key={inx} >
                    <Card sx={{ p: 1, mx: 0.8, }} >
                      <Skeleton variant="rectangular" width='100%' height={210} />

                      <CardContent sx={{ py: 1, px: 1, }}>
                        <Skeleton width="80%" />
                      </CardContent>
                      <Divider />
                      <CardActions disableSpacing>
                        <Skeleton width="60%" />

                      </CardActions>

                    </Card>
                  </Box> */}