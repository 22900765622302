import React from 'react'
import HomeIcon from '@mui/icons-material/Home';
import { Container, Breadcrumbs, Link } from '@mui/material';
import { NavLink } from "react-router-dom"


function BreadcrumbsNav({ pageName, pageLink, childPageName }) {
    return (
        <div role="presentation" className='breadcrumb'>
            <Container maxWidth='xl'>
                <Breadcrumbs maxItems={3} aria-label="breadcrumb">
                    <NavLink to="/">
                        <span><HomeIcon /> Home </span>
                    </NavLink>

                    {pageName ?
                        pageLink ?
                            <NavLink to={pageLink}> {pageName} </NavLink>
                            :
                            <NavLink to='#'> {pageName} </NavLink>

                        : " "
                    }
                    {childPageName ?
                        <NavLink to="#">{childPageName}  </NavLink>
                        : ""
                    }

                </Breadcrumbs>
            </Container>
        </div>
    )
}

export default BreadcrumbsNav