import React from 'react'
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Typography, Divider } from '@mui/material'
function ConformationPopup({ title, msg, btnText, hideModal, show, Accept }) {
    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth='xs'
                open={show}
                onClose={hideModal}
            >
                <DialogTitle> <Typography variant='subtitle1' color='text.secondary'> {title}</Typography></DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography variant='body2' >
                        {msg}
                    </Typography>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={hideModal} variant="outline" color="secondary" >Close</Button>
                    <Button onClick={Accept} variant="contained" color="error" disableElevation sx={{fontWeight:600,textTransform:'capitalize'}}>  {btnText} </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConformationPopup; 