import React from 'react'
import { Card, CardContent, CardMedia, Typography, Box, Stack} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';


const Cards = ({ Class, imgUrl, title, description, PostDate, Category, DonorAgency, GrantSize, DeallineDaate, Type }) => {
    return (
        <>
            <Card className={Class ? Class : ''}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', px: 1.5, py: 2.5, position: 'relative' }}>
                    <Box
                        height='140px'
                        width='170px'
                    >
                        <CardMedia
                            component="img"
                            image={imgUrl}
                            alt={title}
                            sx={{
                                borderRadius: '5px',
                                objectFit: 'cover',
                                maxHeight: '100%',
                                maxWidth: '100%',
                                height: '100%',
                            }}
                        />
                    </Box>


                    <CardContent sx={{ py: 0, width: '470px', pb: "0px !important" }}>
                        {title ?
                            <Typography fontWeight='bold' variant="subtititle1" color="text.active" component="div"
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '1',
                                    WebkitBoxOrient: 'vertical',
                                }}
                            >
                                {title}
                            </Typography>
                            : ""}

                        {description ?
                            <Typography variant="body1" mt={0.8}
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '3',
                                    WebkitBoxOrient: 'vertical',
                                }}
                            >
                                {description}
                            </Typography>
                            : ""}

                        {PostDate ?
                            <Typography variant="body1" mt={0.8}>
                                <Typography
                                    variant="body1"
                                    color="text.active"
                                    component="span"
                                    fontWeight='bold'
                                >
                                    Post Date: </Typography> {PostDate}
                            </Typography>
                            : ""}
                        {Category ?
                            <Typography variant="body1" mt={0.8}>
                                <Typography
                                    variant="body1"
                                    color="text.active"
                                    component="span"
                                    fontWeight='bold'
                                >
                                    Category:  </Typography>{Category}
                            </Typography>
                            : ""}

                        {DonorAgency ?
                            <Typography variant="body1" mt={0.8}>
                                <Typography
                                    variant="body1"
                                    color="text.active"
                                    component="span"
                                    fontWeight='bold'


                                >
                                    Donor Agency :  </Typography> {DonorAgency}
                            </Typography>
                            : ""}

                        {GrantSize ?
                            <Typography variant="body1" mt={0.8}>
                                <Typography
                                    variant="body1"
                                    color="text.active"
                                    component="span"
                                    fontWeight='bold'
                                >
                                    Grant Size :  </Typography>{GrantSize}
                            </Typography>
                            : ""}

                        {DeallineDaate ?
                            <Typography variant="body1" mt={0.8} >
                                <Typography
                                    variant="body1"
                                    color="text.active"
                                    component="span"
                                    fontWeight='bold'

                                >
                                    Deadline Date :  </Typography> {DeallineDaate}
                            </Typography>
                            : ""}
                    </CardContent>
                    {Type ?
                        <Stack  position="absolute" right={0}>
                            <Typography
                                variant="body1"
                                component='span'
                                sx={{
                                    bgcolor: 'background.primary',
                                    color: "white",
                                    borderRadius: '0 0 10px 10px',
                                    display: 'block',
                                    ml: 0,
                                    padding: '3px',
                                    position: "absolute",
                                    textAlign: 'center',
                                    top: "50%",
                                    transform: ' translateX(-59%) translateY(-50%) rotate(90deg)',
                                    width: "130px",
                                }}
                            >
                                {Type}
                            </Typography>
                        </Stack>
                        : ""}
                </Box>
            </Card>


        </>
    )
}

export default Cards;