import React, { memo } from "react";

import Appstore from "../../images/Appstore1.png"
import Googlestore from "../../images/GooglePlay1.png"
import { Box, Container, Grid, Stack, List, ListItem, ListItemText, ListItemAvatar, Typography, Link } from "@mui/material";

// ICON 

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PinDropIcon from '@mui/icons-material/PinDrop';


const Footer = () => {
    return (
        <>
            <footer>
                <Box sx={{
                    background: '#000',
                    padding: '40px 0 30px'
                }}>
                    <Container maxWidth='xl'>
                        <Grid container columnSpacing={3} alignContent='center'>
                            <Grid item xs={12} sm={4}>
                                <Stack alignItems='center' justifyContent='center'>
                                    <List >
                                        <ListItem>
                                            <ListItemAvatar>
                                                <PinDropIcon sx={{ color: 'white', fontSize: "38px" }} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{ color: "white" }} variant='subtitle2'>
                                                        FUNDSFORNGOS, LLC
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography sx={{ color: "white" }} >
                                                        140 Broadway<br />46th Floor<br />New York, NY 10005<br />United States
                                                    </Typography>
                                                } />
                                        </ListItem>
                                    </List>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={4} >
                                <Stack alignItems='center' justifyContent='center' height='100%'>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <LocalPhoneIcon sx={{ color: 'white', fontSize: "38px" }} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{ color: "white" }} variant='subtitle2'>
                                                        Customer Care
                                                    </Typography>
                                                }
                                                secondary={
                                                    <>
                                                        <Typography sx={{ color: "white" }} display='block' component='span'>
                                                            +1 855 666 2396
                                                        </Typography>

                                                        
                                                            <Link target="_blank" href="https://www2.fundsforngospremium.com/support" rel="noopener noreferrer" >
                                                            <Typography sx={{ color: "text.active" }} component='span' >Support</Typography> 
                                                            </Link>
                                                       
                                                    </>

                                                } />
                                        </ListItem>
                                    </List>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={4} >
                                <Stack alignItems='center' justifyContent='center' textAlign='center' height='100%'>

                                    <Box>
                                        <Link href="https://apps.apple.com/us/app/fundsforngos-premium/id1596628153" target="_blank" rel="noopener noreferrer" >
                                            <img style={{ border: '1px solid white', marginRight: '20px' }} src={Appstore} alt="App Link" width="30%" />

                                        </Link>
                                        <Link href="https://play.google.com/store/apps/details?id=com.fundsforngos.premium" target="_blank" rel="noopener noreferrer" >
                                            <img style={{ border: '1px solid white' }} src={Googlestore} alt="App Link" width="30%" />
                                        </Link>
                                    </Box>


                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Box  sx={{
                    bgcolor: 'background.custom',
                    py: 1,
                }}>
                    <Container maxWidth='xl'>
                        <Grid container columnSpacing={3} alignItems='center'>
                            <Grid item xs={12} md={4}  textAlign='center' >
                                <Typography sx={{ color: 'text.white' }}>© Copyright {new Date().getFullYear()}. All Rights Reserved.</Typography>
                            </Grid>

                            <Grid item xs={12} md={4} >
                                <Stack direction='row' spacing={3} justifyContent='center' >
                                    <Link underline="none" variant="body1" target="_blank"  sx={{ color: 'text.white' }} href="https://support.fundsforngos.org/hc/en-us/categories/200373952-fundsforNGOs-Premium" rel="noopener noreferrer" > <Typography sx={{ color: "text.white" }} component='span' > FAQ</Typography> </Link>
                                    <Link underline="none" variant="body1"  sx={{ color: 'text.white' }} target="_blank" href="https://www2.fundsforngospremium.com/support" rel="noopener noreferrer" > <Typography sx={{ color: "text.white" }} component='span' > Contact Us</Typography> </Link>
                                </Stack>

                            </Grid>

                            <Grid item xs={12} md={3} >
                                <Stack direction='row' spacing={3} justifyContent='center'>
                                    <Link className='socialMedaidLink' variant="body1" href="https://www.facebook.com/fundsforngos" target="_blank" rel="noopener noreferrer" title="Facebook ">
                                        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="20px" height="20px">    <path d="M12,27V15H8v-4h4V8.852C12,4.785,13.981,3,17.361,3c1.619,0,2.475,0.12,2.88,0.175V7h-2.305C16.501,7,16,7.757,16,9.291V11 h4.205l-0.571,4H16v12H12z" /></svg>
                                    </Link>
                                    <Link className='socialMedaidLink' variant="body1" href="https://twitter.com/fundsforngos" target="_blank" rel="noopener noreferrer" title="Twitter" >
                                        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="20px" height="20px">    <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" /></svg>
                                    </Link>
                                    <Link className='socialMedaidLink' variant="body1" href="https://www.quora.com/profile/fundsforNGOs-2" target="_blank" rel="noopener noreferrer" title="Quora" >
                                        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20px" height="20px"><path d="M 15.492188 4 C 10.201187 4 5 8.2304531 5 14.439453 C 5 21.556453 11.968844 26.19875 18.214844 24.46875 C 19.260844 26.25175 20.638625 28 23.265625 28 C 27.606625 28 28 24 28 23 L 26.445312 23 C 26.365313 23.716 25.877594 24.457031 24.683594 24.457031 C 23.595594 24.457031 22.810641 23.704594 22.056641 22.558594 C 24.402641 20.743594 25.949219 17.9135 25.949219 14.4375 C 25.954219 8.2025 20.894188 4 15.492188 4 z M 15.492188 6 C 19.536188 6 21.261719 8.9094531 21.261719 14.439453 C 21.266719 16.657453 20.973047 18.450484 20.373047 19.771484 C 19.338047 18.221484 18.054578 17.001953 15.517578 17.001953 C 13.898578 17.001953 12.792719 17.473344 12.011719 18.152344 L 12.658203 19.443359 C 12.987203 19.284359 13.193688 19.289062 13.554688 19.289062 C 15.438688 19.289062 16.404562 20.924969 17.226562 22.542969 C 16.695563 22.701969 16.126234 22.765625 15.490234 22.765625 C 11.510234 22.765625 9.7851563 19.952219 9.7851562 14.449219 C 9.7861563 8.9082188 11.512187 6 15.492188 6 z" /></svg>
                                    </Link>
                                    <Link className='socialMedaidLink' variant="body1" href="https://www.instagram.com/fundsforngos/" target="_blank" rel="noopener noreferrer" title="Instagram" >
                                        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="20px" height="20px">    <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z" /></svg>
                                    </Link>
                                    <Link className='socialMedaidLink' variant="body1" href="https://www.youtube.com/user/fundsforngos" target="_blank" rel="noopener noreferrer" title="YouTube">
                                        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="20px" height="20px"><path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z" /></svg>
                                    </Link>
                                </Stack>
                            </Grid>
                        </Grid>


                    </Container>
                </Box>

            </footer>

        </>
    )
}



export default memo(Footer)