import React, { useState } from 'react'
import {
  List,
  ListItem,

  Collapse,
  Typography,
  
} from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { hasChildren } from './utils';
import { NavLink } from 'react-router-dom';

export const MenuItems = ({ item, closeMenu}) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return  <Component item={item}  closeMenu={closeMenu}   />;
};



const SingleLevel = ({ item,closeMenu }) => {
  return (
    <>
    <ListItem button onClick={closeMenu} >
      <NavLink to={item.to}  >
        <Typography variant='h6' color='text.secondary'>
          {item.title}
        </Typography>
      </NavLink>
     
      {/* <ListItemText primary={item.title} /> */}
    </ListItem>
 
    </>
  );
};



const MultiLevel = ({ item,closeMenu }) => {
 
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.preventDefault() 
    setOpen((prev) => !prev);
  };


  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}  >

        <NavLink to={item.to}  >
          <Typography variant='h6' color='text.secondary'>
            {item.title}
          </Typography>
        </NavLink>
        {open ? <ExpandLessIcon sx={{marginLeft:'auto'}} /> : <ExpandMoreIcon sx={{marginLeft:'auto'}} />}
    
      </ListItem>
  
      <Collapse in={open} timeout="auto" unmountOnExit  >
        <List component="div" disablePadding   >
          {children.map((child, key) => (
            
            <MenuItems key={key} item={child} closeMenu={closeMenu}   />
      
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

