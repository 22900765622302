import React,{useEffect} from 'react'
import { Link, Container, Box, Grid, Typography, Chip, Divider, TableContainer, Table, TableHead, TableRow, TableBody, Button,Stack } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import BreadcrumbsNav from '../ReusableComponents/BreadcrumbsNav';

import Logo from "../../images/logo.png"

import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.secondary,
        fontSize: '0.875rem',
        borderRadius: 1,
        fontWeight: '600'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '0.875rem',
        border: "0"
    },
}));

function InvoicesDetail() {

    useEffect(() => {
        document.title = "	My Invoices   -  fundsforNGOs Premium"
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
            <BreadcrumbsNav pageName="My Invoices" pageLink='/myinvoices' childPageName='US-202103-00482/USD-01' />
            <Box className='main_section' sx={{ bgcolor: 'background.default' }}>
                <Container maxWidth='xl'>
                    <Box p={4} sx={{ bgcolor: 'background.paper' }} >
                        <Grid container columnSpacing={3} rowSpacing={2}>
                            <Grid item xs={6} >
                                <Box
                                    component="img"
                                    sx={{
                                        height: "100%",
                                        maxHeight: { xs: "100%", md: "80%" },
                                        maxWidth: { xs: "100%", md: "80%" },
                                    }}
                                    alt="Logo"
                                    src={Logo}
                                />
                            </Grid>
                            <Grid item xs={6}  >
                                <Stack direction='row' alignItems='center' justifyContent='end'>
                                    <Typography variant='subtitle2' mr={2} color='text.secondary'>INVOICE</Typography>
                                    <Chip label='PAID' sx={{ bgcolor: 'rgba(84, 214, 44, 0.16)', color: 'rgb(34, 154, 22)', fontWeight: 600 }} />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 1, }} />

                        <Grid container columnSpacing={3} rowSpacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography variant='body2' fontWeight='bold' color='text.secondary'>
                                    FUNDSFORNGOS, LLC
                                </Typography>

                                <Typography color='text.primary'>140 Broadway <br />
                                    46th Floor <br />
                                    New York, NY 10005 <br />
                                    United States <br />
                                    <strong>Tel</strong>: +1 855 666 2396 <br />
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Typography variant='body2' fontWeight='bold' color='text.secondary'>
                                    Vinay KD
                                </Typography>

                                <Typography color='text.primary'>Doon Business park <br />
                                    Dehradun1 India 248001 <br />
                                    <strong>PH:</strong>: 9997411882 <br />
                                    <strong>Email:</strong> vinaykd1981@gmail.com <br />
                                    <strong>GSTIN Name: </strong> Deepankar Rastogi <br />
                                    <strong>GSTIN Number: </strong>369852 <br />
                                    <strong>State: </strong>Uttarakahnd <br />
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Typography color='text.primary'>
                                    <strong>Invoice#: </strong> IN-202003-00032/INR-10
                                </Typography>
                                <Typography color='text.primary'>
                                    <strong>Invoice Date: </strong> March 18, 2020
                                </Typography>
                                <Typography color='text.primary'>
                                    <strong>Amount: </strong> 11.8
                                </Typography>
                            </Grid>

                        </Grid>

                        <Grid container columnSpacing={3} mt={3} >
                            <Grid item xs={12}>
                                <TableContainer  >
                                    <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Desc</StyledTableCell>
                                                <StyledTableCell align="left">Description.</StyledTableCell>
                                                <StyledTableCell align="left"> Item</StyledTableCell>
                                                <StyledTableCell align="left">Plan duration	</StyledTableCell>

                                                <StyledTableCell align="left">Qty</StyledTableCell>
                                                <StyledTableCell align="left">Line Total </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow>
                                                <StyledTableCell>Subscription	</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <Typography>
                                                        1 Year Subscription to
                                                        <Link href='https://fundsforngospremium.com/' target='_blank' display='block'>fundsforNGOs Premium membership</Link>
                                                        TEST10RS
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    Plan duration: March 18, 2020 <br /> to April 01, 2020
                                                    Premium Standard Membership
                                                </StyledTableCell>
                                                <StyledTableCell align="left">10.00	</StyledTableCell>
                                                <StyledTableCell align="left">1	</StyledTableCell>
                                                <StyledTableCell align="left">10.00	</StyledTableCell>
                                            </TableRow>


                                            <TableRow sx={{ borderTop: '1px solid lightgray' }}>
                                                <StyledTableCell sx={{ fontWeight: 'bold', py: 1, px: 2 }}> Payment Method</StyledTableCell>
                                                <StyledTableCell sx={{ py: 1, px: 2, }}>PayPal/Credit Card </StyledTableCell>
                                                <StyledTableCell sx={{ py: 1, px: 2, }} colSpan={2} />

                                                <StyledTableCell sx={{ fontWeight: 'bold', py: 1, px: 2, }}>Total</StyledTableCell>
                                                <StyledTableCell sx={{ py: 1, px: 2, }} align="left"> 10.00 INR</StyledTableCell>
                                            </TableRow>
                                            <TableRow >
                                                <StyledTableCell sx={{ py: 1, px: 2, }}> <strong>Paypal Handler Request</strong></StyledTableCell>
                                                <StyledTableCell sx={{ py: 1, px: 2, }} colSpan={3} />

                                                <StyledTableCell sx={{ fontWeight: 'bold', py: 1, px: 2, }}> Total GST</StyledTableCell>
                                                <StyledTableCell sx={{ py: 1, px: 2, }} align="left">  	0.00 USD</StyledTableCell>
                                            </TableRow>



                                            <TableRow>
                                                <StyledTableCell sx={{ py: 1, px: 2, }} colSpan={4} />
                                                <StyledTableCell sx={{ fontWeight: 'bold', py: 1, px: 2, }} align="left">Net Amount		</StyledTableCell>
                                                <StyledTableCell sx={{ py: 1, px: 2, }} align="left">11.80 INR</StyledTableCell>
                                            </TableRow>



                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>

                        <Grid container columnSpacing={3}>
                            <Grid item xs={12} >
                                <Typography color='text.primary'>NOTE: This is a computer generated invoice and does not require a signature</Typography>

                                <Typography color='text.primary'>Primary URL: <Link rel="noopener noreferrer" target='_blank'  href='https://fundsforngos.org' >https://fundsforngos.org. </Link></Typography>

                                <Typography color='text.primary'>Premium URL:<Link rel="noopener noreferrer" target='_blank' href='https://fundsforngospremium.com' > https://fundsforngospremium.com.</Link></Typography>

                                <Typography color='text.primary' > For any queries please contact us at <Link rel="noopener noreferrer" target='_blank' href='https://support.fundsforngos.org/' >https://support.fundsforngos.org/</Link></Typography>
                                <Typography color='text.secondary' textAlign='center' mt={3} mb={2} variant='h1' fontWeight='bold'> THANK YOU FOR YOUR BUSINESS!</Typography>
                                <Stack textAlign='center' >
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        disableElevation
                                        sx={{ m: 'auto' }}
                                        startIcon={<LocalPrintshopIcon />}
                                    >
                                        Print
                                    </Button>
                                </Stack>

                            </Grid>
                        </Grid>

                    </Box>
                </Container>
            </Box>

        </>
    )
}

export default InvoicesDetail;