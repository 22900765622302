import React, { memo } from "react";
import { Grid, Typography, Stack } from "@mui/material";

const Heading = ({ heading }) => {
    return (
        <>
            <Grid container columnSpacing={3}>
                <Grid item xs={12} >
                    <Stack textAlign='center' >
                        <Typography variant="h1" color='text.active'
                            sx={{
                                position: 'relative',
                            }}>
                            <Typography variant="span" color='text.active' fontWeight='600'
                                sx={{
                                    position: 'relative',
                                    border: '2px solid',
                                    padding: '4px 10px',
                                  
                                }}>
                            {heading}
                        </Typography>

                    </Typography>
                </Stack>
            </Grid>
        </Grid>


        </>
    )
}

export default memo(Heading);
