import React, { useEffect } from 'react';
import {
    Box,
    Container,
    Grid, TableContainer, Table, TableHead, TableRow, TableBody, Typography, Chip, TablePagination, Divider

} from '@mui/material'
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

//componets 
import BreadcrumbsNav from '../ReusableComponents/BreadcrumbsNav';


function createData(InvoiceId, InvoiceDate, PlanAmount, NetAmount, PaymentType, PlanDuration, Status) {
    return { InvoiceId, InvoiceDate, PlanAmount, NetAmount, PaymentType, PlanDuration, Status };
}

const rows = [
    createData('US-202103-00482/USD-01', "March 19, 2021", "USD 1", 'USD 199', 'Paid via PayPal/Credit Card', '12 Month April 01, 2022', 'Completed'),
    createData('US-202003-00310/USD-01', "March 19, 2020", "USD 1", 'USD 199', 'Paid via PayPal/Credit Card', '12 Month April 01, 2022', 'Completed'),
    createData('201904-00004/USD-01', "April 04, 2019	", "USD 1", 'USD 199', 'Paid via Stripe	', '12 Month April 01, 2022', 'Completed'),
    createData('US-202003-003450/USD-01', "March 19, 2020", "USD 1", 'USD 199', 'Paid via PayPal/Credit Card', '12 Month April 01, 2022', 'Completed'),
    createData('US-202003-4551/USD-01', "March 19, 2020", "USD 1", 'USD 199', 'Paid via PayPal/Credit Card', '12 Month April 01, 2022', 'Completed'),

];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.secondary,
        fontSize: '0.875rem',
        borderRadius: 1,
        fontWeight: '600'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '0.875rem',
        borderBottom: '1px solid #f4f5f9'
    },
}));



const Invoices = () => {

    useEffect(() => {
        document.title = "	My Invoices   -  fundsforNGOs Premium"
        window.scrollTo(0, 0);
    }, [])

    const handleChangePage = () => {
    }



    return (
        <>
            <BreadcrumbsNav pageName="My Invoices" />
            <Box className='main_section' sx={{ bgcolor: 'background.default' }}>
                <Container maxWidth='xl'>
                    <Grid container columnSpacing={4}>
                        <Grid item xs={12} >
                            <Box p={1} sx={{ bgcolor: 'background.paper' }} >
                                <Typography variant='h2' fontWeight='bold' color='text.active' px={2} py={2}>My Invoices</Typography>
                                <TableContainer >

                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>#Invoice Id</StyledTableCell>
                                                <StyledTableCell align="right">Invoice Date	</StyledTableCell>
                                                <StyledTableCell align="right">Plan Amount	</StyledTableCell>
                                                <StyledTableCell align="right">Net Amount	</StyledTableCell>
                                                <StyledTableCell align="right">Payment Type	</StyledTableCell>
                                                <StyledTableCell align="right">Plan Duration		</StyledTableCell>
                                                <StyledTableCell align="right">Status		</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow
                                                    key={row.InvoiceId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.InvoiceId}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">{row.InvoiceDate}</StyledTableCell>
                                                    <StyledTableCell align="right">{row.PlanAmount}</StyledTableCell>
                                                    <StyledTableCell align="right">{row.NetAmount}</StyledTableCell>
                                                    <StyledTableCell align="right">{row.PaymentType}</StyledTableCell>
                                                    <StyledTableCell align="right">{row.PlanDuration}</StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <Chip label={row.Status} sx={{ bgcolor: 'rgba(84, 214, 44, 0.16)', color: 'rgb(34, 154, 22)', fontWeight: 600 }} />
                                                    </StyledTableCell>
                                                </TableRow>

                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Divider />
                                <TablePagination
                                    component="div"
                                    count={200}
                                    page={1}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={10}
                                //onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>

        </>
    )
}

export default Invoices