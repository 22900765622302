import React,{useEffect} from 'react'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
//componets 
import BreadcrumbsNav from '../ReusableComponents/BreadcrumbsNav'
import Heading from '../ReusableComponents/Heading'
function PaymentDetails() {

    useEffect(() => {
        document.title = "	Payment Details  -  fundsforNGOs Premium"
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
            <BreadcrumbsNav pageName="Change Password" />
            <Box className='main_section' sx={{ bgcolor: 'background.default' }}>
                <Container maxWidth='xl'>
                    <Grid container columnSpacing={4}>
                        <Grid item xs={12} md={12} xl={5} m='auto'>
                            <Box p={5} sx={{ bgcolor: 'background.paper' }} >
                                <Heading heading='Update Your Payment Details' />
                                <Typography mt={4} fontWeight='600' variant='subtitle2' color='text.primary'>    Stripe  </Typography> 
                                <Typography   mt={1} color='text.primary'> 
                                You have selected non-recurring payment method, or your auto-renewal is not active. Please contact us for further details
                                </Typography>
                                <Button variant='contained' color='primary' sx={{ mt:3,}} disableElevation>  Contact us </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default PaymentDetails