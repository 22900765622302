import React, { useState,useEffect } from 'react'
import { Box, Button, Container, Grid, Pagination, Stack, Typography, } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// components
import Heading from '../ReusableComponents/Heading'
import BreadcrumbsNav from '../ReusableComponents/BreadcrumbsNav'
import Cards from '../ReusableComponents/Cards'
import PlaceHolder from '../ReusableComponents/PlaceHolder';
import AlertsPopup from '../ReusableComponents/AlertsPopup';
// icons
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';


const top100Films = [
    { label: 'The Shawshank Redemption', value: 0 },
    { label: 'The Godfather', value: 1 },
    { label: 'The Godfather: Part II', value: 2 },
    { label: 'The Dark Knight', value: 3 },
    { label: '12 Angry Men', value: 4 },
    { label: "Schindler's List", value: 5 },
    { label: 'Pulp Fiction', value: 6 },
]



const Opportunities = () => {

    const [selectCountry, setSelectCountry] = useState([]);

    const [alert, setAlert] = useState({
        open: false,
        time: 1000,
        type: 'success',
        msg: '',
    })


    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    // date formate function 
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }
    var startdate = convert(startDate);
    var enddate = convert(endDate);
    if (startdate === "1970-01-01") startdate = null;
    if (enddate === "1970-01-01") enddate = null;


    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };

    
    useEffect(() => {
        document.title = "Latest Funding Grants   - fundsforNGOs Premium"
        window.scrollTo(0, 0);
    }, [])

    const handleOpen = () => {
        setAlert({
            open: true,
            time: 3000,
            type: 'error',
            msg: 'This is an error message!',
        })
    }

    //alert 
    const onHide = () => {
        setAlert({
            open: false,
            time: 1000,
            type: 'success',
            msg: '',
        })
    }


    return (
        <>

            <BreadcrumbsNav pageName="Latest Grants" />

            <AlertsPopup open={alert.open} time={alert.time} type={alert.type} msg={alert.msg} onHide={onHide} />

            <Box className='main_section' sx={{ bgcolor: 'background.default' }}>
                <Container maxWidth='xl'>
                    <Heading heading="Latest Grants" />
                    <Box borderRadius="4px" mt={4} p={4} sx={{ bgcolor: 'primary.light' }}>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} md={3} mb={{ xs: 3, md: 0 }}>
                                <MultiSelect className="form-control border-0 p-0"
                                    options={top100Films}
                                    value={selectCountry}
                                    onChange={setSelectCountry}
                                    placeholder="Country ..."
                                    overrideStrings={{
                                        selectSomeItems: "Select  Country...",
                                        allItemsAreSelected: "All Country  Selected",
                                        selectAll: "Select All Country ",
                                        search: "Search",
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={3} mb={{ xs: 3, md: 0 }}>
                                <MultiSelect className="form-control border-0 p-0"
                                    options={top100Films}
                                    value={selectCountry}
                                    onChange={setSelectCountry}
                                    placeholder="Country ..."
                                    overrideStrings={{
                                        selectSomeItems: "  Areas of Interest ...",
                                        allItemsAreSelected: "All Areas of Interest Selected",
                                        selectAll: "Select All Areas of Interest.. ",
                                        search: "Search",
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, md: 0 }}>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} md={6} mb={{ xs: 3, md: 0 }}>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}

                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            adjustDateOnChange
                                            className="form-control"
                                            placeholderText="Publish From"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} >
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}

                                            minDate={startDate}
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            adjustDateOnChange
                                            className="form-control"
                                            placeholderText="Publish To"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={12} lg={2} mt={{ xs: 0, md: 3, lg: 0, }}>
                                <Box textAlign={{ xs: 'center', lg: 'left' }}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        disableElevation
                                        sx={{ textTransform: "capitalize", fontWeight: '500', mr: 1, }}

                                    >
                                        Search
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        color='primary'

                                        disableElevation
                                        sx={{ textTransform: "capitalize", fontWeight: '500', bgcolor: 'background.paper' }} >   Reset </Button>

                                </Box>

                                <NavLink to="/advancesearch" style={{ marginTop: '2px' }} >
                                    <Stack display='flex' direction='row' justifyContent={{ xs: 'center', lg: 'start' }}>
                                        <Typography fontWeight='bold' color='text.active'>Advanced Search     </Typography> <ArrowRightAltIcon sx={{ color: 'text.active' }} />
                                    </Stack>
                                </NavLink>

                            </Grid>



                        </Grid>
                    </Box>

                    <Grid container mt={4} columnSpacing={3}>
                        <Grid item xs={12} md={6}>
                            <Cards
                                link="/abc"
                                Class="main_card"
                                imgUrl="https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/t4ykdGYZ6ahNPWouzzMqUHUIIyzmLziu4kqBnXIN.jpg"
                                title={<NavLink to='/opportunity/op/details' > Fast Forward Announces Accelerator For Tech Nonprofits </NavLink>}
                                //description="The Fast Forward is inviting applications for the tech and nonprofit worlds that provides training and resources applicable to the challenges only a tech nonprofit"
                                //PostDate="August 24, 2022"
                                Category="Grant"
                                DonorAgency="Bank of Scotland Foundation"
                                GrantSize=" $10,000 to $100,000"
                                DeallineDaate="September 30, 2022"
                                Type="Opportunity"
                            />

                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Cards
                                link="/abc"
                                Class="main_card"
                                imgUrl="https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/t4ykdGYZ6ahNPWouzzMqUHUIIyzmLziu4kqBnXIN.jpg"
                                title={<NavLink to='/opportunity/op/details' > Fast Forward Announces Accelerator For Tech Nonprofits </NavLink>}
                                //description="The Fast Forward is inviting applications for the tech and nonprofit worlds that provides training and resources applicable to the challenges only a tech nonprofit"
                                //PostDate="August 24, 2022"
                                Category="Grant"
                                DonorAgency="Bank of Scotland Foundation"
                                GrantSize=" $10,000 to $100,000"
                                DeallineDaate="September 30, 2022"
                                Type="Opportunity"
                            />

                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Cards
                                link="/abc"
                                Class="main_card"
                                imgUrl="https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/t4ykdGYZ6ahNPWouzzMqUHUIIyzmLziu4kqBnXIN.jpg"
                                title={<NavLink to='/opportunity/op/details' > Fast Forward Announces Accelerator For Tech Nonprofits </NavLink>}
                                //description="The Fast Forward is inviting applications for the tech and nonprofit worlds that provides training and resources applicable to the challenges only a tech nonprofit"
                                //PostDate="August 24, 2022"
                                Category="Grant"
                                DonorAgency="Bank of Scotland Foundation"
                                GrantSize=" $10,000 to $100,000"
                                DeallineDaate="September 30, 2022"
                                Type="Opportunity"
                            />

                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Cards
                                link="/abc"
                                Class="main_card"
                                imgUrl="https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/t4ykdGYZ6ahNPWouzzMqUHUIIyzmLziu4kqBnXIN.jpg"
                                title={<NavLink to='/opportunity/op/details' > Fast Forward Announces Accelerator For Tech Nonprofits </NavLink>}
                                //description="The Fast Forward is inviting applications for the tech and nonprofit worlds that provides training and resources applicable to the challenges only a tech nonprofit"
                                //PostDate="August 24, 2022"
                                Category="Grant"
                                DonorAgency="Bank of Scotland Foundation"
                                GrantSize=" $10,000 to $100,000"
                                DeallineDaate="September 30, 2022"
                                Type="Opportunity"
                            />

                        </Grid>



                        <>
                            {Array.from({ length: 4 }, (_, i) => <Grid key={i} item xs={12} md={6} mb={{ xs: 3, md: 3, }}><PlaceHolder /></Grid>)}
                        </>

                        <Grid item xs={12} md={12}>
                            <Stack alignItems='center' mt={3}>
                                <Pagination count={10} page={page} onChange={handleChange} variant="outlined" color="primary" shape="rounded" showFirstButton showLastButton />

                            </Stack>
                        </Grid>

                    </Grid>

                </Container>
            </Box>
        </>
    )
}

export default Opportunities