import React, { useState } from 'react'
import { Box, Grid, Container, Typography, Stack, Button, Chip, Alert } from '@mui/material';
import { MultiSelect } from "react-multi-select-component";
//componets
import Heading from '../ReusableComponents/Heading';
import BreadcrumbsNav from '../ReusableComponents/BreadcrumbsNav';

//icon
import DeleteIcon from '@mui/icons-material/Delete';


const top100Films = [
    { label: 'The Shawshank Redemption', value: 0 },
    { label: 'The Godfather', value: 1 },
    { label: 'The Godfather: Part II', value: 2 },
    { label: 'The Dark Knight', value: 3 },
    { label: '12 Angry Men', value: 4 },
    { label: "Schindler's List", value: 5 },
    { label: 'Pulp Fiction', value: 6 },
]
const SpecificFunding = () => {
    const [selectCountry, setSelectCountry] = useState([]);

    const handleDelete = () => {

    }

    return (
        <>
            <BreadcrumbsNav pageName="Specific Funding Alert Setting" />
            <Box className='main_section' sx={{ bgcolor: 'background.default' }}>
                <Container maxWidth='xl'>
                    <Heading heading="Specific Funding Alert Setting" />

                    <Box sx={{ p: 3, mt: 4, bgcolor: 'background.paper' }}>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={12} pb={3}>
                                <Typography color='text.primary'> Specific Email Funding Alerts is a special type of feature for Premium Members where they can opt-in to receive information about the latest grants and other related resources based on their country/countries and/or thematic area/s of work or interest directly in their email inbox. This feature is useful to keep track of the latest and relevant opportunities in your sector and for your location so that you can apply on time. After you select and save your preferences below, you will start receiving specific and relevant email funding alerts and other related information based on your country/countries and thematic area/s of work or interest.</Typography>
                            </Grid>

                            <Grid item xs={12} md={6} pb={3}  >
                                <Typography fontWeight='bold' text='text.primary' mb={0.5}> Add your Country/Countries: </Typography>
                                <Grid container columnSpacing={1} mb={1} alignItems='center'>
                                    <Grid item xs={8} md={10}   >
                                        <MultiSelect className="form-control border-0 p-0"
                                            options={top100Films}
                                            value={selectCountry}
                                            onChange={setSelectCountry}
                                            placeholder="Country ..."
                                            overrideStrings={{
                                                selectSomeItems: "Select  Country...",
                                                allItemsAreSelected: "All Country  Selected",
                                                selectAll: "Select All Country ",
                                                search: "Search",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={2} textAlign='right'   >
                                        <Button variant='contained' color='primary' disableElevation> Save </Button>
                                    </Grid>
                                </Grid>

                                <Typography color='text.primary' fontWeight='bold' variant='h6' mb={0.5}>*Refer to the countries listed below</Typography>
                                <Typography color='text.primary' fontWeight='bold' variant='h6'>You have 3 country/countries selected.</Typography>

                                <Alert sx={{ mt: 3, fontSize: "14px", }} severity="warning">Please note that as you have not selected any country, you will receive email funding alerts for all countries and/or the country selected during registration</Alert>
                                <Box mt={3}>

                                    <Chip
                                        label="Custom delete icon"
                                        sx={{ fontWeight: '600', mr: 1, mb: 1.5, }}
                                        onDelete={handleDelete}
                                        deleteIcon={<DeleteIcon sx={{ color: '#f44336 !important' }} />}
                                    />

                                </Box>

                            </Grid>

                            <Grid item xs={12} md={6} pb={3}  >
                                <Typography fontWeight='bold' text='text.primary' mb={0.5}>Add your Thematic Areas of Interest/work : </Typography>
                                <Grid container columnSpacing={1} mb={1} alignItems='center'>
                                    <Grid item xs={8} md={10}   >
                                        <MultiSelect className="form-control border-0 p-0"
                                            options={top100Films}
                                            value={selectCountry}
                                            onChange={setSelectCountry}
                                            placeholder="Country ..."
                                            overrideStrings={{
                                                selectSomeItems: "Select  Country...",
                                                allItemsAreSelected: "All Country  Selected",
                                                selectAll: "Select All Country ",
                                                search: "Search",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={2} textAlign='right'   >
                                        <Button variant='contained' color='primary' disableElevation> Save </Button>
                                    </Grid>
                                </Grid>
                                <Typography color='text.primary' fontWeight='bold' variant='h6' mb={0.5}>*Refer to the thematic areas of work/interest listed below</Typography>
                                <Typography color='text.primary' fontWeight='bold' variant='h6'>You have 3 thematic areas of interest/work selected.</Typography>

                                <Alert sx={{ mt: 3, fontSize: "14px", }} severity="warning">Please note that as you have not selected any country, you will receive email funding alerts for all countries and/or the country selected during registration</Alert>

                                <Box mt={3}>

                                    <Chip
                                        label="Custom delete icon"
                                        sx={{ fontWeight: '600', mr: 1, mb: 1.5, }}
                                        onDelete={handleDelete}
                                        deleteIcon={<DeleteIcon sx={{ color: '#f44336 !important' }} />}
                                    />

                                </Box>
                            </Grid>

                        </Grid>

                    </Box>




                </Container>
            </Box>

        </>
    )
}

export default SpecificFunding;