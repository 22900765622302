import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box, Container, Grid, Tabs, Tab, Typography, Card, CardMedia, Divider, CardContent,
    CardActions,
    Skeleton,
    Button,
} from '@mui/material'
import { NavLink } from 'react-router-dom'
//componets 
import BreadcrumbsNav from '../ReusableComponents/BreadcrumbsNav'
import Heading from '../ReusableComponents/Heading';
import ConformationPopup from '../ReusableComponents/ConformationPopup ';
//icon 
import DeleteIcon from '@mui/icons-material/Delete';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, bgcolor: 'background.paper' }}>
                    <Typography component='div'>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Favorites() {
    const [value, setValue] = useState(0);

    const [ConfModal, setConfModal] = useState(false);
    const [msg, setMsg] = useState({
        title: '',
        msg: '',
        btnText: ''
    });

    useEffect(() => {
        document.title = "My Favorites -  fundsforNGOs Premium"
        window.scrollTo(0, 0);
    }, [])

    //confirmation Modal
    const hideModal = useCallback(() => {
        setConfModal(false)
    }, [ConfModal])

    const removeOpted = useCallback(() => {
        setConfModal(true)
        setMsg({
            title: "Are you sure?",
            msg: "You won't be able to revert this!",
            btnText: 'Yes, delete it!'
        })

    }, [ConfModal])


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };




    return (
        <>
            <BreadcrumbsNav pageName="My Favorites" />
            <Box className='main_section' sx={{ bgcolor: 'background.default' }}>
                <Container maxWidth='xl'>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} >
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"
                                        sx={{ bgcolor: 'background.paper' }}>

                                        <Tab sx={{ fontWeight: 600, textTransform: 'capitalize' }} label="Funding Opportunities (22)"  {...a11yProps(0)} />
                                        <Tab sx={{ fontWeight: 600, textTransform: 'capitalize' }} label="Sample Proposals (1)" {...a11yProps(1)} />
                                        <Tab sx={{ fontWeight: 600, textTransform: 'capitalize' }} label="eBooks (2)" {...a11yProps(2)} />
                                        <Tab sx={{ fontWeight: 600, textTransform: 'capitalize' }} label="Job Listing  (4)" {...a11yProps(3)} />
                                        <Tab sx={{ fontWeight: 600, textTransform: 'capitalize' }} label="Featured Articles  (3)" {...a11yProps(4)} />
                                        <Tab sx={{ fontWeight: 600, textTransform: 'capitalize' }} label="Training Videos (2)"  {...a11yProps(5)} />
                                        <Tab sx={{ fontWeight: 600, textTransform: 'capitalize' }} label=" Donor News (2)" {...a11yProps(6)} />
                                        <Tab sx={{ fontWeight: 600, textTransform: 'capitalize' }} label=" Donor Profiles  (6)" {...a11yProps(7)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0} >
                                    <Grid container>
                                        <Grid item xs={12} sx={{ mb: 3, }}>
                                            <Heading heading='Funding Opportunities' font='1' />
                                        </Grid>

                                        <Grid item xs={6} md={2}>
                                            <Card sx={{ p: 1, mx: 0.8, }} >
                                                <NavLink to='/'>
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ borderRadius: 1, }}
                                                        image="https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/uu7ywwIyOKiFbm1b7Iv0tnkUrhp1z1eLYT6rx5xn.jpg"
                                                        alt='Submit Applications for the Resilience Fund for Women'
                                                    />
                                                </NavLink>
                                                <CardContent sx={{ py: 1, px: 1, }}>
                                                    <NavLink to='/' style={{ textDecoration: "none", }}>
                                                        <Typography color="text.active" component='span' sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            display: '-webkit-box',
                                                            WebkitLineClamp: '3',
                                                            WebkitBoxOrient: 'vertical',
                                                            height: '65px'

                                                        }}>
                                                            Submit Applications for the Resilience Fund for Women
                                                        </Typography>
                                                    </NavLink>
                                                </CardContent>

                                                <CardActions disableSpacing>
                                                    <Button onClick={removeOpted} variant="contained" color="error" sx={{ width: '100%' }}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </CardActions>

                                            </Card>
                                        </Grid>

                                        <Grid item xs={6} md={2}>
                                            <Card sx={{ p: 1, mx: 0.8, }} >
                                                <NavLink to='/'>
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ borderRadius: 1, }}
                                                        image="https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/uu7ywwIyOKiFbm1b7Iv0tnkUrhp1z1eLYT6rx5xn.jpg"
                                                        alt='Submit Applications for the Resilience Fund for Women'
                                                    />
                                                </NavLink>
                                                <CardContent sx={{ py: 1, px: 1, }}>
                                                    <NavLink to='/' style={{ textDecoration: "none", }}>
                                                        <Typography color="text.active" component='span' sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            display: '-webkit-box',
                                                            WebkitLineClamp: '3',
                                                            WebkitBoxOrient: 'vertical',
                                                            height: '65px'

                                                        }}>
                                                            Submit Applications for the Resilience Fund for Women
                                                        </Typography>
                                                    </NavLink>
                                                </CardContent>

                                                <CardActions disableSpacing>
                                                    <Button onClick={removeOpted} variant="contained" color="error" sx={{ width: '100%' }}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </CardActions>

                                            </Card>
                                        </Grid>

                                        <Grid item xs={6} md={2}>
                                            <Card sx={{ p: 1, mx: 0.8, }} >
                                                <Skeleton variant="rectangular" width='100%' height={210} />
                                                <CardContent sx={{ py: 1, px: 1, }}>
                                                    <Skeleton width="90%" />
                                                    <Skeleton width="70%" />
                                                    

                                                </CardContent>

                                                <CardActions disableSpacing>
                                                    <Skeleton sx={{bgcolor:'error.light'}} height='46px' width="100%" />
                                                </CardActions>

                                            </Card>
                                        </Grid>



                                    </Grid>
                                </TabPanel>

                                <TabPanel value={value} index={1}>
                                    Item Two
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    Item Three
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    Item Three
                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                    Item Three
                                </TabPanel>
                                <TabPanel value={value} index={5}>
                                    Item Three
                                </TabPanel>
                                <TabPanel value={value} index={6}>
                                    Item Three
                                </TabPanel>
                                <TabPanel value={value} index={7}>
                                    Item Three
                                </TabPanel>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* ConformationPopup */}
            <ConformationPopup title={msg.title} msg={msg.msg} btnText={msg.btnText} show={ConfModal} hideModal={hideModal} Accept={removeOpted} />

        </>
    )
}