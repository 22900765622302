import React from 'react'
import { Card, CardContent, Skeleton, Box, Stack, } from '@mui/material';

function PlaceHolder() {
    return (
        
        <Card>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', px: 1.5, py: 2.5, position: 'relative' }}>
                <Box
                    height='140px'
                    width='170px'
                >
                    <Skeleton variant="rectangular" width={170} height={140} />
                </Box>


                <CardContent sx={{ py: 0, width: '470px', pb: "0px !important" }}>
                <Skeleton sx={{ bgcolor: 'primary.light' }} animation="wave" />
                <Skeleton    height={85} animation="wave" />
                <Skeleton width='80%' animation="wave" />
                <Skeleton width='60%' animation="wave" />
                   
                </CardContent>
                
                    <Stack position="absolute" right={0}>
                        <Skeleton
                            variant="body1"
                            component='span'
                            sx={{
                               
                                bgcolor: 'primary.light',
                                borderRadius: '0 0 10px 10px',
                                display: 'block',
                                ml: 0,
                                padding: '5px',
                                position: "absolute",
                                textAlign: 'center',
                                top: "50%",
                                transform: ' translateX(-56%) translateY(-50%) rotate(90deg)',
                                width: "130px",
                            }}
                         />

                     </Stack>
                   
            </Box>
        </Card>
    )
}

export default PlaceHolder