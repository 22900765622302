import React, { useState } from 'react'
import { Box, Button, Container, Grid, Pagination, Stack, InputBase, styled, Autocomplete, TextField, FormControlLabel, Switch, } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// components
import Heading from '../ReusableComponents/Heading'
import Cards from '../ReusableComponents/Cards'
import PlaceHolder from '../ReusableComponents/PlaceHolder';
import AlertsPopup from '../ReusableComponents/AlertsPopup';
import BreadcrumbsNav from '../ReusableComponents/BreadcrumbsNav';


const top100Films = [
    { label: 'The Shawshank Redemption', value: 0 },
    { label: 'The Godfather', value: 1 },
    { label: 'The Godfather: Part II', value: 2 },
    { label: 'The Dark Knight', value: 3 },
    { label: '12 Angry Men', value: 4 },
    { label: "Schindler's List", value: 5 },
    { label: 'Pulp Fiction', value: 6 },
]

//filter by options
const FilterBy = [{ value: "PD", label: "Post Date" }, { value: "DD", label: "Deadline Date" }];

//GrantSize
const GrantSize = [
    { value: "0", label: "All" },
    { value: "Less than $1000", label: "Less than $1000" },
    { value: "$1000 to $10,000", label: "$1000 to $10,000" },
    { value: "$10,000 to $100,000", label: "$10,000 to $100,000" },
    { value: "$100,000 to $500,000", label: "$100,000 to $500,000" },
    { value: "$500,000 to $1 million", label: "$500,000 to $1 million" },
    { value: "More than $1 million", label: "More than $1 million" }
]

//grant type options 
const GrantType = [
    { value: "0", label: "All" },
    { value: "1", label: "Deadline-Free Grants" },
    { value: "21", label: "Awards & Prizes" },
    { value: "26", label: "Events" },
    { value: "22", label: "Grants" },
    { value: "28", label: "Training" },
    { value: "11", label: "Fellowship" }
]


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 14,

        height: '36px',
        width: '100%',
        padding: '0px 10px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),

        '&:focus': {
            // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

function AdvanceSearchOpty() {

    const [Filter, setFilter] = useState('');


    const [selectCountry, setSelectCountry] = useState([]);

    const [alert, setAlert] = useState({
        open: false,
        time: 1000,
        type: 'success',
        msg: '',
    })

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    // date formate function 
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }
    var startdate = convert(startDate);
    var enddate = convert(endDate);
    if (startdate === "1970-01-01") startdate = null;
    if (enddate === "1970-01-01") enddate = null;


    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleOpen = () => {
        setAlert({
            open: true,
            time: 3000,
            type: 'error',
            msg: 'This is an error message!',
        })
    }

    //alert 
    const onHide = () => {
        setAlert({
            open: false,
            time: 1000,
            type: 'success',
            msg: '',
        })
    }

    return (
        <>
            <BreadcrumbsNav pageName="Advanced Grant Search" />
            <AlertsPopup open={alert.open} time={alert.time} type={alert.type} msg={alert.msg} onHide={onHide} />

            <Box className='main_section' sx={{ bgcolor: 'background.default' }}>
                <Container maxWidth='xl'>
                    <Heading heading="Advanced Grant Search" />
                    <Box borderRadius="4px" mt={4} p={4} sx={{ bgcolor: 'primary.light' }}>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} md={4} mb={{ xs: 3, md: 3 }}>
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    id="combo-box-demo"
                                    options={FilterBy}
                                    renderInput={(params) => <TextField {...params} sx={{ color: 'text.secondary' }} placeholder="Filter By.." />}
                                    sx={{
                                        bgcolor: 'background.paper', color: 'text.secondary', borderRadius: '0px', '&:hover': {

                                            borderColor: 'none',
                                        },
                                    }}

                                />
                            </Grid>


                            <Grid item xs={12} md={8} lg={8} mb={{ xs: 3, md: 3 }}>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={6} sm={6} md={6} >
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}

                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            adjustDateOnChange
                                            className="form-control"
                                            placeholderText="Publish From"
                                        />
                                    </Grid>

                                    <Grid item xs={6} sm={6} md={6} >
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}

                                            minDate={startDate}
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            adjustDateOnChange
                                            className="form-control"
                                            placeholderText="Publish To"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} sm={6} md={2} mb={{ xs: 3, md: 3 }}>
                                <MultiSelect className="form-control border-0 p-0"
                                    options={top100Films}
                                    value={selectCountry}
                                    onChange={setSelectCountry}
                                    placeholder="Region ..."
                                    overrideStrings={{
                                        selectSomeItems: "Select  Region...",
                                        allItemsAreSelected: "All Region  Selected",
                                        selectAll: "Select All Region ",
                                        search: "Search",
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={2} mb={{ xs: 3, md: 3 }}>
                                <MultiSelect className="form-control border-0 p-0"
                                    options={top100Films}
                                    value={selectCountry}
                                    onChange={setSelectCountry}
                                    placeholder="Country ..."
                                    overrideStrings={{
                                        selectSomeItems: "Select Country...",
                                        allItemsAreSelected: "All Country  Selected",
                                        selectAll: "Select All Country ",
                                        search: "Search",
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={2} mb={{ xs: 3, md: 3 }}>
                                <MultiSelect className="form-control border-0 p-0"
                                    options={top100Films}
                                    value={selectCountry}
                                    onChange={setSelectCountry}
                                    placeholder="Country ..."
                                    overrideStrings={{
                                        selectSomeItems: "  Areas of Interest ...",
                                        allItemsAreSelected: "All Areas of Interest Selected",
                                        selectAll: "Select All Areas of Interest.. ",
                                        search: "Search",
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={2} mb={{ xs: 3, md: 3 }}>
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    id="Grant_Size"
                                    options={GrantSize}
                                    renderInput={(params) => <TextField {...params} sx={{ color: 'text.secondary' }} placeholder="Grant Size..." />}
                                    sx={{
                                        bgcolor: 'background.paper', color: 'text.secondary', borderRadius: '0px', '&:hover': {

                                            borderColor: 'none',
                                        },
                                    }}

                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={2} mb={{ xs: 3, md: 3 }}>
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    id="Grant_Type"
                                    options={GrantType}
                                    renderInput={(params) => <TextField {...params} sx={{ color: 'text.secondary' }} placeholder="Grant Type..." />}
                                    sx={{
                                        bgcolor: 'background.paper', color: 'text.secondary', borderRadius: '0px', '&:hover': {

                                            borderColor: 'none',
                                        },
                                    }}

                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={2} mb={{ xs: 3, md: 3 }}>
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    id="Donor_Agency"
                                    options={GrantType}
                                    renderInput={(params) => <TextField {...params} sx={{ color: 'text.secondary' }} placeholder="Donor Agency.." />}
                                    sx={{
                                        bgcolor: 'background.paper', color: 'text.secondary', borderRadius: '0px', '&:hover': {

                                            borderColor: 'none',
                                        },
                                    }}

                                />
                            </Grid>


                            <Grid item xs={12} sm={12} md={10} mb={{ xs: 3, md: 3 }}>
                                <BootstrapInput sx={{ width: '100%' }} placeholder="Search Everything" id="bootstrap-input" />
                            </Grid>

                            <Grid item xs={12} md={12} lg={2} mt={{ xs: 0, md: 3, lg: 0, }}>
                                <Box textAlign={{ xs: 'center', lg: 'left' }}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        disableElevation
                                        sx={{ textTransform: "capitalize", fontWeight: '500', mr: 1, }}

                                    >
                                        Search
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        color='primary'

                                        disableElevation
                                        sx={{ textTransform: "capitalize", fontWeight: '500', bgcolor: 'background.paper' }} >   Reset </Button>

                                </Box>
                                <Stack display='flex' direction='row' justifyContent={{ xs: 'center', lg: 'start' }}>
                                    <FormControlLabel control={<Switch color="primary" defaultChecked />} label="Include Expired" />
                                </Stack>


                            </Grid>



                        </Grid>



                    </Box>

                    <Grid container mt={4} columnSpacing={3}>
                        <Grid item xs={12} md={6}>
                            <Cards
                                link="/abc"
                                Class="main_card"
                                imgUrl="https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/t4ykdGYZ6ahNPWouzzMqUHUIIyzmLziu4kqBnXIN.jpg"
                                title={<NavLink to='/opportunity/op/details' > Fast Forward Announces Accelerator For Tech Nonprofits </NavLink>}
                                //description="The Fast Forward is inviting applications for the tech and nonprofit worlds that provides training and resources applicable to the challenges only a tech nonprofit"
                                //PostDate="August 24, 2022"
                                Category="Grant"
                                DonorAgency="Bank of Scotland Foundation"
                                GrantSize=" $10,000 to $100,000"
                                DeallineDaate="September 30, 2022"
                                Type="Opportunity"
                            />

                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Cards
                                link="/abc"
                                Class="main_card"
                                imgUrl="https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/t4ykdGYZ6ahNPWouzzMqUHUIIyzmLziu4kqBnXIN.jpg"
                                title={<NavLink to='/opportunity/op/details' > Fast Forward Announces Accelerator For Tech Nonprofits </NavLink>}
                                //description="The Fast Forward is inviting applications for the tech and nonprofit worlds that provides training and resources applicable to the challenges only a tech nonprofit"
                                //PostDate="August 24, 2022"
                                Category="Grant"
                                DonorAgency="Bank of Scotland Foundation"
                                GrantSize=" $10,000 to $100,000"
                                DeallineDaate="September 30, 2022"
                                Type="Opportunity"
                            />

                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Cards
                                link="/abc"
                                Class="main_card"
                                imgUrl="https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/t4ykdGYZ6ahNPWouzzMqUHUIIyzmLziu4kqBnXIN.jpg"
                                title={<NavLink to='/opportunity/op/details' > Fast Forward Announces Accelerator For Tech Nonprofits </NavLink>}
                                //description="The Fast Forward is inviting applications for the tech and nonprofit worlds that provides training and resources applicable to the challenges only a tech nonprofit"
                                //PostDate="August 24, 2022"
                                Category="Grant"
                                DonorAgency="Bank of Scotland Foundation"
                                GrantSize=" $10,000 to $100,000"
                                DeallineDaate="September 30, 2022"
                                Type="Opportunity"
                            />

                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Cards
                                link="/abc"
                                Class="main_card"
                                imgUrl="https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/t4ykdGYZ6ahNPWouzzMqUHUIIyzmLziu4kqBnXIN.jpg"
                                title={<NavLink to='/opportunity/op/details' > Fast Forward Announces Accelerator For Tech Nonprofits </NavLink>}
                                //description="The Fast Forward is inviting applications for the tech and nonprofit worlds that provides training and resources applicable to the challenges only a tech nonprofit"
                                //PostDate="August 24, 2022"
                                Category="Grant"
                                DonorAgency="Bank of Scotland Foundation"
                                GrantSize=" $10,000 to $100,000"
                                DeallineDaate="September 30, 2022"
                                Type="Opportunity"
                            />

                        </Grid>



                        <>
                            {Array.from({ length: 4 }, (_, i) => <Grid key={i} item xs={12} md={6} mb={{ xs: 3, md: 3, }}><PlaceHolder /></Grid>)}
                        </>

                        <Grid item xs={12} md={12}>
                            <Stack alignItems='center' mt={3}>
                                <Pagination count={10} page={page} onChange={handleChange} variant="outlined" color="primary" shape="rounded" showFirstButton showLastButton />

                            </Stack>
                        </Grid>

                    </Grid>

                </Container>
            </Box>

        </>
    )
}

export default AdvanceSearchOpty;