import './main.scss'
import React, { useContext } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { Routes, Route, useLocation } from 'react-router-dom';
// components
import Header from './components/ReusableComponents/Header';
import Footer from './components/ReusableComponents/Footer';
import Home from './components/Home/Home'
// grants
import Opportunities from './components/Grants/Opportunities';
import Maps from './components/Grants/Maps';
import CompaniesOpty from './components/Grants/CompaniesOpty';
import DeadlineCalendar from './components/Grants/DeadlineCalendar';
import AdvanceSearchOpty from './components/Grants/AdvanceSearchOpty';
import OpDetails from './components/Grants/OpDetails';
// alerts
import SpecificFunding from './components/Alert/SpecificFunding';
// user profile
import Favorites from './components/UserProfile/Favorites';
import UserDetails from './components/UserProfile/UserDetails';
import PaymentDetails from './components/UserProfile/PaymentDetails';
import Invoices from './components/UserProfile/Invoices';
import InvoicesDetail from './components/UserProfile/InvoicesDetail';
import ForgetPass from './components/UserProfile/ForgetPass';
//login
import Login from './components/Login/Login';



// context 
import ThemeContext from './components/ReusableComponents/Context/Themecontext';


function App() {
  const location = useLocation();
  const { selectedTheme } = useContext(ThemeContext)

  return (
    <>

      <ThemeProvider theme={selectedTheme}>
        <CssBaseline />

        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          {/* grants */}
          <Route path="/opportunity" element={<Opportunities />} />
          <Route path="/maps" element={<Maps />} />
          <Route path='/calendar' element={<DeadlineCalendar />} />
          <Route path="/opportunity/op/details" element={<OpDetails />} />
          <Route path='/specific-funding-alerts' element={<SpecificFunding />} />
          <Route path='/companies-opportunities' element={<CompaniesOpty />} />
          <Route path='/advancesearch' element={<AdvanceSearchOpty />} />
          {/* user Profile */}
          <Route path="/favorites" element={<Favorites />} />
          <Route path='/mydetails' element={<UserDetails />} />
          <Route path='/myinvoices' element={<Invoices />} />
          <Route path='/resetpassword' element={<ForgetPass />} />
          <Route path='/mypaymentdetails' element={<PaymentDetails />} />
          {/* login */}
          <Route path='/login' element={<Login />} />


          <Route path='/myinvoices/:id' element={<InvoicesDetail />} />





        </Routes>


        {location.pathname === '/' || location.pathname === '/login' ? "" : <Footer />}

      </ThemeProvider>

    </>

  )
}

export default App;
