import React, { useState } from 'react'
import { Card, Box, Stack, Avatar, IconButton, Typography, Divider, TextField } from '@mui/material';
//icon
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';



function SideBar() {
    const todayDate = new Date();

    const [value, setValue] = React.useState(dayjs());

    const [showMobile, SetshowMobile] = useState(false);

    const [show, setShow] = useState(true);
    const handleClick = () => {
        setShow(!show);
    }


    return (
        <>
            <Box display={{ xs: 'block', md: 'none', }} >
                <Stack direction='row' alignItems='center' sx={{ p: 1, position: "relative", bgcolor: 'background.paper' }}>

                    <Typography
                        variant='subtitle1'
                        sx={{ py: 0.8, }}
                        color='text.active'
                    >
                        {showMobile ? "" : "Welcome  xyz"}
                    </Typography>
                    <Stack
                        sx={{ width: '36px', height: '36px', ml: 'auto', borderRadius: '50%', bgcolor: 'background.primary' }}
                    >
                        <IconButton onClick={() => SetshowMobile(!showMobile)}>
                            {showMobile ?
                                <KeyboardArrowUpIcon sx={{ fontSize: 20, color: 'white' }} />
                                :
                                <KeyboardArrowDownIcon sx={{ fontSize: 20, color: 'white' }} />
                            }
                        </IconButton>
                    </Stack>
                </Stack>
            </Box>


            <Stack
                display={{ xs: showMobile ? 'block' : "none", md: 'block', }}
                sx={{
                    position: "sticky",
                    top: "20px",
                }}>
                <Card className='user-profile' sx={{ boxShadow: 2, }}>
                    <Box sx={{ p: 2, textAlign: "center", position: "relative", }}>
                        <Stack
                            sx={{ position: 'absolute', top: 10, right: 10, borderRadius: '50%', bgcolor: 'background.primary' }}
                        >
                            <IconButton onClick={handleClick}>
                                {show ?
                                    <KeyboardArrowUpIcon sx={{ fontSize: 20, color: 'white' }} />
                                    :
                                    <KeyboardArrowDownIcon sx={{ fontSize: 20, color: 'white' }} />
                                }
                            </IconButton>
                        </Stack>

                        <Avatar bgcolor='primary'
                            sx={{ m: "auto", width: '55px', height: '55px', bgcolor: 'background.primary' }}
                            variant="rounded"
                            src="avatar1.jpg"

                        />

                        <Stack spacing={0.5}>

                            <Typography
                                variant='subtitle1'
                                sx={{ py: 0.8, }}
                                color='text.secondary'
                            >
                                Welcome fundsforNGOs
                            </Typography>

                            <Typography sx={{
                                mt: 0,
                            }}>
                                Your registered email address is
                            </Typography>

                            <Typography variant="body1" fontWeight='700'>
                                priyanka@fundsforngos.org
                            </Typography>
                        </Stack>
                    </Box>
                    {show ?
                        <>
                            <Divider />
                            <Stack
                                alignItems="left"
                                justifyContent="space-between"
                                sx={{ px: 2, py: 1, }}
                            >
                                <Typography sx={{ pb: 0.9, }}>
                                    Your next membership renewal is on <strong>April 01, 2025.</strong> Update Your Card Details
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <Typography sx={{ pb: 0.9, }}>
                                    You have marked <strong>41</strong> postings as your favorites.Review them
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <Typography sx={{ pb: 0.9, }} >
                                    You have selected <strong>8</strong> countries and <strong>1</strong> thematic areas of work/interest for receiving grant information.Update
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <Typography sx={{ pb: 0.9, }} >
                                    You can download your invoices here.
                                </Typography>

                            </Stack>
                        </>
                        : ""
                    }
                </Card>

                <Card className='user-profile' sx={{
                    mt: 4,
                }}>
                    <Stack direction='row' alignItems='center' sx={{ p: 2, position: "relative", }}>

                        <Typography
                            variant='subtitle1'
                            sx={{ py: 0.8, }}
                            color='text.secondary'
                        >
                            Deadline Calendar

                        </Typography>
                        <Stack
                            sx={{ width: '36px', height: '36px', ml: 'auto', borderRadius: '50%', bgcolor: 'background.primary' }}
                        >


                            <IconButton onClick={handleClick}>
                                {show ?
                                    <KeyboardArrowDownIcon sx={{ fontSize: 20, color: 'white' }} />
                                    :
                                    <KeyboardArrowUpIcon sx={{ fontSize: 20, color: 'white' }} />
                                }
                            </IconButton>
                        </Stack>
                    </Stack>

                    {show ? "" :
                        <Box sx={{ mx: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <StaticDatePicker
                                    sx={{
                                        bgcolor: 'primary.main', color: 'white', width: '100%',
                                        
                                    }}
                                    orientation="portrait"
                                    //orientation="landscape"
                                    DialogProps={{ sx:{ '& .PrivateDatePickerToolbar-penIcon': {display: 'none'} } }}
                                    openTo="day"
                                    value={value}
                                    minDate={todayDate.getFullYear() - 15 + ""}
                                    maxDate={todayDate.getFullYear() + 15 + ""}
                                    views={["year", "month", "day"]}

                                    onChange={(newValue) => {
                                       debugger;
                                        setValue(newValue);
                                    }}
                                    
                                    // onYearChange={(newValue) => {
                                    //     debugger;
                                    //     setValue(newValue);
                                    // }}
                                    onMonthChange={(newValue) => {
                                        debugger;
                                        setValue(newValue);
                                    }}

                                   
                                    renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                                    componentsProps={{
                                        actionBar: {
                                            actions: ['today'],
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>
                    }

                </Card>

            </Stack>

        </>
    )
}

export default SideBar