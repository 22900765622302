import React, { useState, useEffect, useContext } from 'react'
import { Box, Container, Grid, Skeleton } from '@mui/material'
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    InfoWindow,
} from '@react-google-maps/api';
import { NavLink } from 'react-router-dom';

import Logo from "../../images/logo.png"
// componets
import BreadcrumbsNav from '../ReusableComponents/BreadcrumbsNav'
import Heading from '../ReusableComponents/Heading'
//context 
import ThemeContext from '../ReusableComponents/Context/Themecontext';

const containerStyle = {
    width: '100%',
    height: '550px',
};


const Maps = () => {
    const { selectedTheme } = useContext(ThemeContext)

 


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDhhV6CoJTiC9SVktaN9eoxBxOhLjo1UXw',
    });
    const [options, setOptions] = useState({})

    const [mapSetting, setMaps] = useState({
        defaultCenter: {
            lat: 37.09024,
            lng: -95.712891,
        },
        zoom: 4.5,
    });

    const [selected, setSelected] = useState({});


    const onSelect = (item) => {
        debugger;
        setSelected(item);
    };


    useEffect(() => {

        window.scrollTo(0, 0);
        document.title = "Grants Map List- fundsforNGOs Premium"
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (selectedTheme.palette.mode === 'dark') {
            setOptions({
                styles: [
                    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
                    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                    {
                        featureType: "administrative.locality",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d59563" }],
                    },
                    {
                        featureType: "poi",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d59563" }],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "geometry",
                        stylers: [{ color: "#263c3f" }],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#6b9a76" }],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry",
                        stylers: [{ color: "#38414e" }],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#212a37" }],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#9ca5b3" }],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [{ color: "#746855" }],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#1f2835" }],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#f3d19c" }],
                    },
                    {
                        featureType: "transit",
                        elementType: "geometry",
                        stylers: [{ color: "#2f3948" }],
                    },
                    {
                        featureType: "transit.station",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d59563" }],
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [{ color: "#17263c" }],
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#515c6d" }],
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.stroke",
                        stylers: [{ color: "#17263c" }],
                    },
                ],
            })
        }
        else {
            setOptions({
                styles: ["default"]
            })
        }

    }, [selectedTheme]);

  

    return isLoaded ? (
        <>
            <BreadcrumbsNav pageName="Grants Map" />

            <Box className='main_section' sx={{ bgcolor: 'background.default' }}>
                <Container maxWidth='xl'>
                    <Heading heading="Grants Map" />
                    <Box borderRadius="4px" mt={4} p={3} sx={{ bgcolor: 'background.paper' }}>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ position: 'relative', height: '550px', }}   >
                                   
                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={mapSetting.defaultCenter}
                                            zoom={mapSetting.zoom}
                                            options={options}
                                            containerStyle={{}} >
                                            {/* {markers.map((val, index) => {
                                                debugger;
                                                return (
                                                    <Marker
                                                         //title={`Available Opportunity(s) ${val.opportunities}`}
                                                        // key={index}
                                                        position={{
                                                            lat: parseInt(val.lat),
                                                            lng: parseInt(val.lng),
                                                        }}
                                                        onClick={() => {
                                                            onSelect(val);

                                                            setMaps({
                                                                defaultCenter: {
                                                                    lat: parseInt(val.lat),
                                                                    lng: parseInt(val.lng),
                                                                },
                                                                zoom: 5,
                                                            });
                                                        }}

                                                    />
                                                );
                                            })}
                                            {selected.name && (
                                                <InfoWindow
                                                    position={{
                                                        lat: parseInt(selected.lat),
                                                        lng: parseInt(selected.lng),
                                                    }}
                                                    clickable={true}
                                                    onCloseClick={() => {
                                                        setMaps({
                                                            defaultCenter: {
                                                                lat: 33.4211,
                                                                lng: 0,
                                                            },
                                                            zoom: 2,
                                                        });
                                                        setSelected({});
                                                    }}
                                                >
                                                    <div className="marker_text">
                                                        <img src={Logo} alt="fundsForngos" style={{ width: "200px" }} />
                                                        <p className="maked_country">{selected.name}</p>
                                                        <NavLink to={`maps/${selected.code}/${selected.name}`} > Available Opportunity(s) : {selected.opportunities} </NavLink>
                                                    </div>

                                                </InfoWindow>
                                            )}
                                           
                                            <></> */}
                                        </GoogleMap>
                                       


                                </Box>

                            </Grid>

                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
   ) : " "

}

export default Maps;