import React, { useState } from "react";
import { createTheme } from "@mui/material";
import ThemeContext from "./Themecontext";

const ThemeState = (props) => {
  const [theme, setData] = useState(false);

  let onChange = (theme) => {
    setData(theme);
  };

  const light = createTheme({
    palette: {
      mode: 'light',  //dark
      primary: {
        main: '#0095c0',
        dark: 'rgb(0, 104, 134)',
        light: 'rgb(51 170 204 / 33%)',

      },
      secondary: {
        main: '#6c757d',
        dark: 'rgb(137, 144, 151)',
        light: 'rgb(75, 81, 87)',
      },
      error: {
        main: '#d32f2f',
        light: '#ef5350',
        dark: '#c62828',
        contrastText: '#fff',
        warning: {
          main: '#ed6c02',
          light: '#ff9800',
          dark: '#e65100',
          contrastText: '#fff',
        },
        info: {
          main: '#0288d1',
          light: '#03a9f4',
          dark: '#01579b',
          contrastText: ' #fff',
        },
        success: {
          main: '#2e7d32',
          light: '#4caf50',
          dark: '#1b5e20',
          contrastText: '#fff',
        }
      },
      background: {
        paper: '#ffffff',
        default: '#f4f5f9',
        primary: '#0095c0',
        custom: '#0095c0',
        white: '#fff'
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.87)',
        disabled: 'rgba(0, 0, 0, 0.38)',
        active: '#0095c0',
        white: '#fff',
      }

    },
    components: {
      MuiCssBaseline: {
        styleOverrides: (themeParam) => `
            .dropdown-container,.panel-content,.select-item {
                background: ${themeParam.palette.background.paper} !important;
                color: ${themeParam.palette.text.secondary} !important;
                 border-radius: 0 !important;
              }
              .dropdown-container:focus-within{
                box-shadow:none !important;
                border:1px solid ${themeParam.palette.text.active} !important;
              }
              .rmsc .gray{
                color: ${themeParam.palette.text.secondary}
              }
              a{
                color: ${themeParam.palette.text.active}
              }
              a:visited {
                color: #681da8;
            }
            .react-datepicker__input-container input::placeholder{
              color: ${themeParam.palette.text.secondary}
            }
            .react-datepicker__input-container input{
              padding: 0.375rem 0.75rem;
              border:1px solid #ccc;
              color: ${themeParam.palette.text.secondary}
            }
            .react-datepicker__input-container input:focus-within{
              box-shadow:none !important;
              border:1px solid ${themeParam.palette.text.active} !important;
              outline:none !important;
            }
           .main_section  .css-1d3z3hw-MuiOutlinedInput-notchedOutline
           {
              border:1px solid;
              border-radius:0px !important;
              border-width:1px  !important;
              border-color: #ccc;
            }
            .css-1d3z3hw-MuiOutlinedInput-notchedOutline:hover{
              border:1px solid #ccc;
            }
            .css-1d3z3hw-MuiOutlinedInput-notchedOutline:focus-within{
              border:1px solid ${themeParam.palette.text.active} !important;
            }
            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
               color: ${themeParam.palette.text.secondary} !important;
               opacity: 1 !important; /* Firefox */
            }
              
            `,
      },
    },
    typography: {
      htmlFontSize: 16,

      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      color: 'text.primary',
      h1: {

        fontWeight: 300,
        fontSize: "1.5rem",
        lineHeight: '160%',
        letterSpacing: "0.00735em",
        fontStyle: "italic"
      },
      h2: {

        fontWeight: 300,
        fontSize: "1.5rem",
        lineHeight: '160%',
        letterSpacing: "0.00735em",
      },
      h6: {

        fontWeight: 300,
        fontSize: "0.75rem",
        lineHeight: '160%',
        letterSpacing: "0.00938em",

      },
      subtitle1: {

        fontWeight: 700,
        fontSize: "1.125rem",
        lineHeight: 1.4,
        letterSpacing: "0.00938em",
      },
      subtitle2: {

        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: 1.75,
        letterSpacing: "0.00938em",
      },
      body1: {

        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: '1.5rem',
        letterSpacing: "0.01071em"
      },
      body2: {

        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: '1.5rem',
        letterSpacing: "0.01071em"
      },
      button: {
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: 1.75,
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
      },
    }
  })

  const dark = createTheme({

    palette: {
      mode: 'dark',  //dark
      primary: {
        main: '#0095c0',
        dark: 'rgb(0, 104, 134)',
        light: 'rgb(51 170 204 / 20%)',
      },
      secondary: {
        main: '#6c757d',
      },
      error: {
        main: '#f44336',
        light: '#e57373',
        dark: '#d32f2f',
        contrastText: '#fff',
        warning: {
          main: '#ffa726',
          light: '#ffb74d',
          dark: '#f57c00',
          contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        info: {
          main: '#29b6f6',
          light: '#4fc3f7',
          dark: '#0288d1',
          contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        success: {
          main: '#66bb6a',
          light: '#81c784',
          dark: '#388e3c',
          contrastText: 'rgba(0, 0, 0, 0.87)',
        }
      },
      background: {
        paper: '#272822',
        default: '#18191a',
        primary: '#0ac8ff',
        custom: '#272822',
        white: 'black',
      },
      text: {
        primary: 'rgba(255, 255, 255, 0.7)',
        secondary: ' #fff',
        disabled: 'rgba(255, 255, 255, 0.5)',
        icon: 'rgba(255, 255, 255, 0.5)',
        active: '#0ac8ff',
        white: 'rgba(255, 255, 255, 0.7)',
      }

    },
    components: {
      MuiCssBaseline: {
        styleOverrides: (themeParam) => `
            .dropdown-container,.panel-content,.select-item,.dropdown-heading {
                background: ${themeParam.palette.background.paper} !important;
                color: ${themeParam.palette.text.secondary} !important;
                 border-radius: 0 !important;
              }
              .dropdown-container:focus-within{
                box-shadow:none !important;
                border:1px solid ${themeParam.palette.text.active} !important;
              }
              .rmsc .gray{
                color: ${themeParam.palette.text.secondary}
              }
              a{
                color: ${themeParam.palette.text.active}
              }
              a:visited {
                color: #af51ff;
            }

            .react-datepicker__input-container input::placeholder{
              color: ${themeParam.palette.text.secondary}
            }
            .react-datepicker__input-container input{
              padding: 0.375rem 0.75rem;
              border:1px solid #ccc;
              color: ${themeParam.palette.text.secondary};
              background: ${themeParam.palette.background.paper} !important;
            }
            .react-datepicker__input-container input:focus-within{
              box-shadow:none !important;
              border:1px solid ${themeParam.palette.text.active} !important;
              outline:none !important;
            }
            .css-9425fu-MuiOutlinedInput-notchedOutline
            {
               border:1px solid;
               border-radius:0px !important;
               border-width:1px  !important;
               border-color: #ccc !important;
             }
             

             .css-9425fu-MuiOutlinedInput-notchedOutline:hover{
               border:1px solid #ccc;
             }
             .css-9425fu-MuiOutlinedInput-notchedOutline:focus-within{
               border:1px solid ${themeParam.palette.text.active} !important;
             }
             ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
               color: ${themeParam.palette.text.secondary} !important;
               opacity: 1 !important; /* Firefox */
            }
           
            `,
      },

    },

    typography: {
      htmlFontSize: 16,
      color: 'text.primary',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h1: {

        fontWeight: 300,
        fontSize: "1.5rem",
        lineHeight: '160%',
        letterSpacing: "0.00735em",
        fontStyle: "italic"
      },
      h2: {

        fontWeight: 300,
        fontSize: "1.5rem",
        lineHeight: '160%',
        letterSpacing: "0.00735em",
      },
      h6: {

        fontWeight: 300,
        fontSize: "0.75rem",
        lineHeight: '160%',
        letterSpacing: "0.00938em",
      },
      subtitle1: {

        fontWeight: 700,
        fontSize: "1.125rem",
        lineHeight: 1.4,
        letterSpacing: "0.00938em",
      },
      subtitle2: {

        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: 1.75,
        letterSpacing: "0.00938em",
      },
      body1: {

        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: '1.5rem',
        letterSpacing: "0.01071em"
      },
      body2: {

        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: '1.5rem',
        letterSpacing: "0.01071em"
      },
      button: {
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: 1.75,
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
      },
    }
  })

  const selectedTheme = theme ? dark : light;

  return (
    <ThemeContext.Provider value={{ selectedTheme, onChange }}  >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeState;