
import React,{useEffect} from 'react';
import {
    Box,
    Container,
    Grid,
    Card,
    Stack,
    Avatar,
    Tooltip,
    Chip,
    Typography,
    CardActions,
    CardContent,
    Divider,
    List, ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField, FormControl, MenuItem, InputLabel, Select, Button
} from '@mui/material'
import { green } from '@mui/material/colors';

//componets 
import BreadcrumbsNav from '../ReusableComponents/BreadcrumbsNav'
//icon 
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import PublicIcon from '@mui/icons-material/Public';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import ApartmentIcon from '@mui/icons-material/Apartment';
const UserDetails = () => {

    useEffect(() => {
        document.title = "My Account  -  fundsforNGOs Premium"
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <BreadcrumbsNav pageName="My Account" />
            <Box className='main_section' sx={{ bgcolor: 'background.default' }}>
                <Container maxWidth='xl'>
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} md={4} lg={3} mb={3}>
                            <Card>
                                <Stack direction="row" justifyContent='end' p={1.5}>
                                    <Chip label="ACTIVE" sx={{ bgcolor: 'rgba(84, 214, 44, 0.16)', color: 'rgb(34, 154, 22)', fontWeight: 600 }} />
                                </Stack>
                                <CardContent>
                                    <Avatar bgcolor='primary'
                                        sx={{ m: "auto", width: '80px', height: '80px', bgcolor: 'background.primary' }}

                                        src="avatar1.jpg"

                                    />
                                    <Tooltip title={"Vinay KD"} arrow placement="bottom">
                                        <Typography mt={2} variant="subtitle1" color="text.active"
                                            sx={{
                                                textAlign: 'center',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: '1',
                                                WebkitBoxOrient: 'vertical',
                                            }}>
                                            Vinay KD
                                        </Typography>
                                    </Tooltip>
                                </CardContent>
                                <Divider />
                                <CardActions disableSpacing sx={{ p: 0, }}>
                                    <List disablePadding sx={{ width: '100%' }}>
                                        <ListItem disablePadding>
                                            <ListItemButton width='100%'>
                                                <ListItemIcon>
                                                    <EmailIcon sx={{ color: 'text.active' }} />
                                                </ListItemIcon>
                                                <ListItemText primary=" vinaykd1981@gmail.com " />
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider />
                                        <ListItem disablePadding>
                                            <ListItemButton width='100%'>
                                                <ListItemIcon>
                                                    <CallIcon sx={{ color: 'text.active' }} />
                                                </ListItemIcon>
                                                <ListItemText primary=" 99974118820 " />
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider />
                                        <ListItem disablePadding>
                                            <ListItemButton width='100%'>
                                                <ListItemIcon>
                                                    <PublicIcon sx={{ color: 'text.active' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="  India " />
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider />
                                        <ListItem disablePadding>
                                            <ListItemButton width='100%'>
                                                <ListItemIcon>
                                                    <ApartmentIcon sx={{ color: 'text.active' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="  Uttarakhand " />
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider />
                                        <ListItem disablePadding>
                                            <ListItemButton width='100%'>
                                                <ListItemIcon>
                                                    <LocationCityIcon sx={{ color: 'text.active' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="  Dehradun " />
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider />
                                        <ListItem disablePadding>
                                            <ListItemButton width='100%'>
                                                <ListItemIcon>
                                                    <LocationOnIcon sx={{ color: 'text.active' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="  21, IT Park, Shahastradhara Road " />
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider />
                                        <ListItem disablePadding>
                                            <ListItemButton width='100%'>
                                                <ListItemIcon>
                                                    <AddLocationAltIcon sx={{ color: 'text.active' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="  248001 " />
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider />

                                    </List>

                                </CardActions>

                            </Card>

                        </Grid>

                        <Grid item xs={12} md={8} lg={9} >
                            <Box sx={{ bgcolor: 'background.paper', p: 4, }}>
                                <Grid container columnSpacing={5} rowSpacing={{ xs: 3, md: 5, }}>
                                    <Grid item xs={12} md={4} >
                                        <TextField
                                            id="outlined-name"
                                            label="Full Name"
                                            defaultValue="Vinay KD"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} >
                                        <TextField
                                            id="outlined-email"
                                            label="Email Address"
                                            defaultValue="vinaykd1981@gmail.com"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4} >
                                        <TextField
                                            id="outlined-number"
                                            label="Phone Number"
                                            defaultValue="99974118820"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12} >
                                        <TextField
                                            fullWidth
                                            id="filled-multiline-flexible"
                                            label="Address"
                                            multiline
                                            rows={4}
                                            defaultValue="21, IT Park, Shahastradhara Road"

                                        />

                                    </Grid>


                                    <Grid item xs={12} md={6} >
                                        <TextField
                                            id="outlined-city"
                                            label="City"
                                            defaultValue="Dehradun"
                                            fullWidth
                                        />
                                    </Grid>



                                    <Grid item xs={12} md={6} >
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">State/Region</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={0}
                                                label="State/Region"
                                                disabled
                                            //onChange={handleChange}
                                            >
                                                <MenuItem value={0}>  Uttarakhand </MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6} >
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={0}
                                                label="Country"
                                                disabled
                                            //onChange={handleChange}
                                            >
                                                <MenuItem value={0}>India</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6} >
                                        <TextField
                                            id="outlined-zip"
                                            label="zip"
                                            defaultValue="248001"
                                            fullWidth
                                        />
                                    </Grid>



                                    <Grid item xs={12} md={12} >
                                        <Button variant="contained" disableElevation>
                                            Update
                                        </Button>
                                    </Grid>


                                </Grid>

                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>
        </>

    )
}

export default UserDetails