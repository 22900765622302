
import React,{useEffect} from 'react'
import {
    Container,
    Grid,
    Avatar,
    Stack,
    IconButton,
    Divider,
    Box,
    Typography,
    BottomNavigationAction,
    BottomNavigation,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar
} from '@mui/material'
import BreadcrumbsNav from '../ReusableComponents/BreadcrumbsNav'

import SideBar from './SideBar';
import MainSection from './MainSection';

//images
import opportunities from '../../images/icon/opportunities.png'
import advancesearch from '../../images/icon/seo.png'
import alerts from '../../images/icon/calendar.png'
import donorprofile from '../../images/icon/donor.png'
import Resources from '../../images/icon/customer.png'

// icon 
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PinDropIcon from '@mui/icons-material/PinDrop';
import CloseIcon from '@mui/icons-material/Close';


function Home() {
    const [value, setValue] = React.useState(1);

    const RightbarData = [
        {
            id: "",
            title: "Grants",
            imageUrl: opportunities,
            description: "List of latest funding opportunities can be filtered by country and area of interest so that you can quickly view what is relevant to you and start applying for them.",
            link: "/opportunity",
        },
        {
            id: "2",
            title: "Advanced Grant Search",
            imageUrl: advancesearch,
            description: "Search grants by country or by thematic area of work/interest, by region, by deadline, by donor and by grant size.",
            link: "/advancesearch",
        },
        {
            id: "3",
            title: "Alerts",
            imageUrl: alerts,
            description: "Get specific grant information by email based on your choices of countries and thematic areas of work/interest - relevant to your needs",
            link: "/specific-funding-alerts",
        },
        {
            id: "4",
            title: "Donor Profiles",
            imageUrl: donorprofile,
            description: "Allows Premium Members to search donors quickly by country, area of interest, and/or keyword.",
            link: "/donorsearch",
        },
        {
            id: "5",
            title: "Resources",
            imageUrl: Resources,
            description: "Sample Proposals, Ebooks, Training Videos, and articles on a range of topics covering fundraising, proposal writing, donor listings, grant resources, and NGO management.",
            link: "/linksresources",
        },
    ]

    useEffect(() => {
        document.title = " Dashboard - fundsforNGOs Premium"
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
            {/* <BreadcrumbsNav pageName="abc" pageLink='home' childPageName='xyz' />  */}
            <BreadcrumbsNav />

            <Box className='main_section' sx={{ bgcolor: 'background.default' }}>
                <Container maxWidth='xl'>
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <SideBar />
                        </Grid>

                        <Grid item xs={12} md={5} lg={6}>
                            <MainSection />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <>

                                <List sx={{ width: '100%', boxShadow: { xs: 2, md: 0, }, bgcolor: { xs: 'background.default', md: 'unset', } }} className={`dashboard-info ${value === 0 ? "active" : "null"}`} >
                                    <Stack alignItems='end'>
                                        <IconButton sx={{ display: { xs: 'block', md: 'none', } }} onClick={() => setValue(1)}>
                                            <CloseIcon color='text.active' />
                                        </IconButton>

                                    </Stack>
                                    {RightbarData.map((val, inx) => {
                                        return (

                                            <ListItem key={inx} sx={{ display: 'block', bgcolor: 'background.paper', mb: 3, pb: 2, boxShadow: 1 }}>
                                                <ListItemText
                                                    variant='div'
                                                    primary={
                                                        <Typography

                                                            component="h6"
                                                            variant="subtitle1"
                                                            color="text.active"
                                                            mb={0.5}
                                                        >
                                                            {val.title}
                                                        </Typography>
                                                    } />
                                                <Divider sx={{ margin: 0, marginBottom: '8px', }} variant="inset" component="p" />
                                                <Stack
                                                    spacing={1}
                                                    direction="row"

                                                >
                                                    <Box
                                                        component="img"
                                                        sx={{
                                                            marginTop: '5px',
                                                            height: "100%",
                                                            maxHeight: { xs: "100%", md: "100%" },
                                                            maxWidth: { xs: "100%", md: "100%" },
                                                        }}
                                                        alt={val.title} src={val.imageUrl}
                                                    />

                                                    <Typography

                                                        component="span"
                                                        variant="body1"
                                                        color="text.primary"
                                                    >
                                                        {val.description}
                                                    </Typography>
                                                </Stack>
                                            </ListItem>

                                        )
                                    })}
                                </List>


                                <List className={`dashboard-contactinfo ${value === 2 ? "active" : "null"}`} sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    subheader={
                                        <>
                                            <Stack direction='row'
                                                alignItems='center'
                                                sx={{
                                                    padding: '10px 18px',
                                                    borderBottom: '1px solid gainsboro',
                                                    boxShadow: 1,
                                                }}>
                                                <Typography component="h2" color="text.active" variant="subtitle1" id="nested-list-subheader"

                                                >
                                                    Contact Us
                                                </Typography>

                                                <IconButton sx={{ ml: 'auto', display: { xs: 'block', md: 'none', } }} onClick={() => setValue(1)}>
                                                    <CloseIcon color='text.active' />
                                                </IconButton>

                                            </Stack>

                                        </>
                                    }
                                >

                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: 'var(--primary-clr)' }} >
                                                <EmailIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Support" />
                                    </ListItem>

                                    <Divider variant="inset" component="div" />

                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: 'var(--primary-clr)' }} >
                                                <LocalPhoneIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    component="h6"
                                                    variant="subtitle2"
                                                    color="text.active"
                                                    mb={0.5}
                                                >
                                                    Customer Care
                                                </Typography>}
                                            secondary="+1 855 666 2396 9 am to 5 pm EST" />

                                    </ListItem>
                                    <Divider variant="inset" component="div" />

                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: 'var(--primary-clr)' }} >
                                                <PinDropIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    component="h6"
                                                    variant="subtitle2"
                                                    color="text.active"
                                                    mb={0.5}
                                                >
                                                    Customer Care
                                                </Typography>}

                                            secondary="Office 1018, 1060 Broadway, Albany New York - 12204 USA" />
                                    </ListItem>
                                </List>

                            </>
                        </Grid>

                    </Grid>
                </Container>
            </Box >

            <Box display={{ xs: 'block', md: 'none', position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 999, }}  >
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    sx={{ background: 'black', }}
                >
                    <BottomNavigationAction sx={{ color: 'white' }} label="Info " icon={<QuestionMarkIcon />} />
                    <BottomNavigationAction sx={{ color: 'white' }} label="Home " icon={<HomeIcon />} />
                    <BottomNavigationAction sx={{ color: 'white' }} label="Contact " icon={<LocationOnIcon />} />
                </BottomNavigation>
            </Box>
        </>
    )
}

export default Home