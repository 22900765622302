import React, { useState, useEffect } from 'react'
import {
    Box,
    Container,
    Grid,
    Stack,
    Typography,
    Checkbox,
    Button,
    Link,
    Divider,
    Tooltip, Card, CardHeader, CardContent, Chip, Skeleton

} from '@mui/material';
import { NavLink } from "react-router-dom"
//components 
import BreadcrumbsNav from '../ReusableComponents/BreadcrumbsNav'
import SlickSlider from '../ReusableComponents/SlickSlider';
// icon 
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const OpDetails = () => {

    const [show, setShow] = useState(false);
    // tooltip hide 
    useEffect(() => {
        const interval1 = setInterval(() => {
            setShow(false)
        }, 1000)
        return () => clearInterval(interval1);
    }, [show])

    useEffect(() => {
        document.title = "Latest Funding Grants   - fundsforNGOs Premium"
        window.scrollTo(0, 0);
    }, [])

    let grant = {

    }

    let items = [
        { "image": "https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/pRA2c2X7y7ARIvTxKIkrooPXtBlhwaw4xJqs6Pau.jpg", "url": "Australia-Tasmanian-Aboriginal-Arts-Mentoring-Scheme", "title": "Australia: Tasmanian Aboriginal Arts Mentoring Scheme", "description": "Tasmania Government is seeking applications for the Tasmanian Aboriginal Arts Mentoring Scheme to support mentorships for young Tasmanian Aboriginal artists with an established Tasmanian Aboriginal artist, and/or Tasmanian arts organisation.", "lastUpdated": "August 29, 2022" },
        { "image": "https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/NdqcjAiipoIOIuGRXpJ6gxVtKBaVvfIW77UdRpJE.jpg", "url": "Cultural-Heritage-Organisations-Program-in-Australia-", "title": "Cultural Heritage Organisations Program in Australia ", "description": "The Tasmania Government is accepting applications for Cultural Heritage Organisations Program.", "lastUpdated": "August 29, 2022" },
        { "image": "https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/BeHBWCwKBMR6rchK8pPTZziHDfOkX28dHg0oyp0y.jpg", "url": "Tasmania-Government-launches-Artsbridge-Program-Australia", "title": "Tasmania Government launches Artsbridge Program (Australia)", "description": "The Tasmania Government has launched the applications for Artsbridge Program to support Tasmanian individuals and groups travelling within Tasmania (intrastate), Australia (interstate) or overseas (international) for arts or cultural heritage opportunities.", "lastUpdated": "August 29, 2022" },
        { "image": "https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/BwaLlfHGvENBaX8OVBDLmcplGbIxt05ejNV2fSvI.jpg", "url": "Australia-Cultural-Heritage-Organisations-Multi-Year-Program-", "title": "Australia: Cultural Heritage Organisations (Multi-Year) Program ", "description": "The Tasmania Government has announced the applications for Cultural Heritage Organisations (Multi-Year) Program.", "lastUpdated": "August 29, 2022" },
        { "image": "https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/3okouM4XP5A633aHaM8XJ1L0awRh8UIHzKMw8lu7.jpg", "url": "CNCDH-2022-Human-Rights-Prize-of-the-French-Republic", "title": "2022 Human Rights Prize of the French Republic", "description": "For the 35th consecutive year, the Nation Consultative Commission on Human Rights (CNCDH) is calling for applications for the Human Rights Prize of the French Republic.", "lastUpdated": "August 29, 2022" },
        { "image": "https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/MkeIHdtyKP5u8OJHH52OiokiwyQRF3Y4sD7lpwru.jpg", "url": "Community-Achievement-Awards-Program-in-Australia", "title": "Community Achievement Awards in Australia", "description": "The City of Melton is accepting applications for its Community Achievement Awards (CAA) Program to recognise the work of individual volunteers or persons that clearly exceed the normal requirements of their paid work.", "lastUpdated": "August 26, 2022" },
        { "image": "https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/hmyg4SHW6bdaqHXert6pEhnbZpNxX8gYbFCBVnNR.jpg", "url": "Ocean-Impact-Ideation-Program-in-Australia", "title": "Ocean Impact Ideation Program in Australia", "description": "The Kirby Foundation and Bank Australia are seeking applications for the Ocean Impact Ideation Program which focuses on turning individuals into oceanpreneurs.", "lastUpdated": "August 26, 2022" },
        { "image": "https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/dqsJcJOUVi50Hju1aCEYWNWlYFP0VX3fezW6o2dT.jpg", "url": "Australia-Ballarat-Senior-of-the-Year-Awards", "title": "Australia: Ballarat Senior of the Year Awards", "description": "The City of Ballarat is now inviting applications for its Ballarat Senior of the Year Awards to recognise the contribution older people in Ballarat make to the community.", "lastUpdated": "August 26, 2022" },
        { "image": "https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/j6aGXnlpEDhoyi7BOIF8Xr7NUGuUTZO4fNcDKS6E.jpg", "url": "Australia-Ararat-Wind-Farm-Sustainable-Grants-Program-2022", "title": "Australia: Ararat Wind Farm Sustainable Grants Program 2022", "description": "Do you have a community or environmental sustainability-based program, project or event that could benefit from funding to support your organisation’s efforts? If yes, then Ararat Wind Farm Sustainable Grants Program 2022 is now open.", "lastUpdated": "August 26, 2022" },
        { "image": "https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/6qhDYDk2AnZqrFNayfanqJvBUIjXYDfPRrlfOuZ3.jpg", "url": "Australia-Melton-Heritage-Assistance-Fund", "title": "Australia: Melton Heritage Assistance Fund", "description": "The City of Melton is now inviting applications for its Melton Heritage Assistance Fund to provide financial assistance to landowners, companies or community groups to maintain, repair or conserve a heritage place or for preparation of conservation management plans for a heritage place within the municipality.", "lastUpdated": "August 26, 2022" },
        { "image": "https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/tGf5lIjOqjScwhWwOGvS8qvIONxKkirp32mBvb1a.jpg", "url": "PAX-Rising-Grants-Program-Australia", "title": "PAX Rising Grants Program (Australia)", "description": "Creative Victoria is seeking applications for its PAX Rising Grants Program to Victorian-based games businesses that have been accepted into PAX Aus 2022.", "lastUpdated": "August 26, 2022" },
        { "image": "https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/lpT0g4ppsMckkhP7ITmq2DJR2mPpi0MALdz4ej4O.jpg", "url": "Mornington-Peninsula-Business-Excellence-Awards-in-Australia", "title": "Mornington Peninsula Business Excellence Awards in Australia", "description": "Nominations are now open for its Business Excellence Awards to recognise innovation, sustainability and excellence across businesses of all types.", "lastUpdated": "August 26, 2022" },
    ]



    return (
        <>
            <BreadcrumbsNav pageName="Funding Grants" pageLink='/opportunity' childPageName='Community Achievement Awards in Australia' />

            <Box className='main_section' sx={{ bgcolor: 'background.default' }}>
                <Container maxWidth='xl'>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={8} xl={9} sx={{ mb: 4, }}>
                            <Box p={1.2} sx={{ bgcolor: 'background.paper' }}>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={3} md={4} xl={4}>
                                        <Box
                                            component="img"
                                            sx={{
                                                height: "100%",
                                                maxHeight: { xs: "100%", md: "100%" },
                                                maxWidth: { xs: "100%", md: "100%" },
                                                borderRadius: 1,

                                            }}
                                            alt="Logo"
                                            src="https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/MkeIHdtyKP5u8OJHH52OiokiwyQRF3Y4sD7lpwru.jpg"
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={9} md={8} xl={8}>
                                        <Stack direction='row' position='relative' alignItems='center'>
                                            <Typography variant='h1' fontWeight='bold' color='text.secondary'
                                                sx={{
                                                    my: 1,
                                                    marginRight: '48px'
                                                }}
                                            >
                                                Community Achievement Awards in Australia
                                            </Typography>
                                            <Tooltip title={"ADD TO FAVORITE"} arrow placement="top">
                                                <Checkbox
                                                    sx={{ boxShadow: 2, ml: 'auto', position: 'absolute', right: 0, top: '5px' }}
                                                    icon={<FavoriteBorder />} checkedIcon={<Favorite />}
                                                />
                                            </Tooltip>
                                        </Stack>

                                        <Stack>
                                            <Typography fontWeight='bold' sx={{ my: 1 }} color='text.secondary' > Important Dates:</Typography>
                                            <Typography color='text.primary' ><strong>Post Date:</strong> August 29, 2022</Typography>
                                            <Divider sx={{ my: 1 }} />
                                            <Typography color='text.primary' ><strong>  Deadline Date:</strong> September 16, 2022</Typography>
                                            <Divider sx={{ my: 1 }} />
                                            <Typography color='text.primary' ><strong>  Donor Name:</strong> CREA</Typography>
                                            <Divider sx={{ my: 1 }} />
                                            <Typography color='text.primary' ><strong>  Grant Size:</strong>  $10,000 to $100,000</Typography>
                                            <Divider sx={{ my: 1 }} />
                                            <Typography color='text.primary'><strong> Category: </strong>  Resilience Fund for Women in Global Value Chains</Typography>
                                            <Divider sx={{ my: 1 }} />
                                            <Stack
                                                direction={{ xs: 'column', sm: 'row' }}
                                                alignItems={{ xs: 'start', sm: 'center' }}
                                            >
                                                <Typography color='text.primary' marginRight={0.6}> <strong >Reference URL:</strong> </Typography>
                                                <Link sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: '1',
                                                    WebkitBoxOrient: 'vertical',

                                                }}
                                                    width={{ xs: '100%', sm: '50%', md: '40%', lg: '50%' }}
                                                    underline="none" variant="body1" target="_blank"
                                                    href='https://www.resiliencefundforwomen.org/apply'
                                                >
                                                    https://www.resiliencefundforwomen.org/apply
                                                </Link>
                                                <Tooltip
                                                    title={"Copied"}
                                                    open={show}
                                                    arrow placement="top"
                                                >
                                                    <Button sx={{ borderRadius: 1, px: 1, marginLeft: 'auto', }}
                                                        variant="contained"
                                                        size="small"
                                                        color="primary"
                                                        disableRipple
                                                        disableElevation
                                                        onClick={() => {
                                                            navigator.clipboard.writeText('https://www.resiliencefundforwomen.org/apply')
                                                            setShow(!show)
                                                        }}
                                                        startIcon={<ContentCopyIcon />}
                                                    >
                                                        Copy Url
                                                    </Button>
                                                </Tooltip>
                                            </Stack>
                                        </Stack>

                                    </Grid>
                                </Grid>
                                
                                <Divider sx={{ my: 1.8, }} />
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} color='text.primary'>
                                        <div className="OP_content" dangerouslySetInnerHTML={{ __html: '<p> Council proudly recognises people whose contributions make this region an even better place to live through the Melton City Council Community Achievement Awards.</p>' }} />
                                    </Grid>
                                </Grid>
                                <Divider sx={{ my: 1.8, }} />
                                <Grid container marginTop={4}>
                                    <SlickSlider heading='Related Country-Based Donors' items={items} />
                                </Grid>
                            </Box>
                        </Grid>




                        <Grid item xs={12} md={4} xl={3} >
                            <Card sx={{ mb: 3, }}>
                                <CardHeader
                                    sx={{
                                        padding: '10px 18px',
                                        borderBottom: '1px solid gainsboro',
                                        boxShadow: 1,
                                    }}
                                    title={
                                        <Typography
                                            component="h2"
                                            color="text.active"
                                            variant="subtitle1" >
                                            Focus Areas of Interest
                                        </Typography>
                                    }

                                />

                                <CardContent>
                                    <Chip sx={{ mr: 1, mb: 1, }} icon={<CheckCircleIcon />} size="small" label="Community Development " />
                                    <Chip sx={{ mr: 1, mb: 1, }} icon={<CheckCircleIcon />} size="small" label="Youth & Adolescents" />
                                </CardContent>
                            </Card>

                            <Card >
                                <CardHeader
                                    sx={{
                                        padding: '10px 18px',
                                        borderBottom: '1px solid gainsboro',
                                        boxShadow: 1,
                                    }}
                                    title={
                                        <Typography
                                            component="h2"
                                            color="text.active"
                                            variant="subtitle1" >
                                            Focus Country(ies)
                                        </Typography>
                                    }

                                />

                                <CardContent>
                                    <NavLink to="/" >
                                        <Chip sx={{ mr: 1, mb: 1, cursor: 'pointer' }} icon={<CheckCircleIcon />} size="small" label="Australia " />
                                    </NavLink>

                                    <NavLink to="/">
                                        <Chip sx={{ mr: 1, mb: 1, cursor: 'pointer' }} icon={<CheckCircleIcon />} size="small" label="India" />
                                    </NavLink>

                                </CardContent>
                            </Card>





                        </Grid>


                    </Grid>
                </Container>

            </Box>

        </>
    )
}

export default OpDetails;