
import { Snackbar, Alert, Slide } from '@mui/material'

function AlertsPopup({ open, time, type, msg, onHide }) {
    const vertical = 'top',
        horizontal = 'right';
        //error  warning  info  success

    return (
        <>
            <Snackbar open={open} autoHideDuration={time} onClose={onHide} anchorOrigin={{ vertical, horizontal }}>
                <Slide direction="up">
                    <Alert variant="filled" onClose={onHide} severity={type} sx={{ width: "100%" }}>
                        {msg}
                    </Alert>
                </Slide>
            </Snackbar>
        </>
    )
}

export default AlertsPopup