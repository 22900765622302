
import React from 'react'
import Cards from '../ReusableComponents/Cards'
import { NavLink } from 'react-router-dom';

import { Box, FormControl, InputBase, Button, styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 14,
       
        color: theme.palette.text.secondary,
        height: '38px',
        width: '100%',
        padding: "0 10px",
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
        
    },
    '& .MuiInputBase-input::placeholder':{
        opacity: '1 !important',
        color: theme.palette.text.secondary,

    }
}));
const MainSection = () => {

    return (
        <>
            <Box borderRadius="4px" mb={3} p={2} sx={{ bgcolor: 'primary.light' }}>
                <FormControl variant="standard" sx={{ display: "flex", width: '100%', flexDirection: "row", }}>
                    <BootstrapInput sx={{ width: '100%' }} placeholder="Search Everything" id="bootstrap-input" />
                    <Button sx={{ borderRadius: 0, }} variant="contained" color="primary" disableRipple disableElevation >
                        <SearchIcon sx={{ m: "0px", }} />
                    </Button>
                </FormControl>



            </Box>

            <Cards

                Class="main_card"
                imgUrl="https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/t4ykdGYZ6ahNPWouzzMqUHUIIyzmLziu4kqBnXIN.jpg"
                title={<NavLink to='/opportunity/op/details' > Fast Forward Announces Accelerator For Tech Nonprofits </NavLink>}
                description="The Fast Forward is inviting applications for the tech and nonprofit worlds that provides training and resources applicable to the challenges only a tech nonprofit"
                PostDate="August 24, 2022"
                // Category="Grant"
                // DonorAgency="Bank of Scotland Foundation"
                // GrantSize=" $10,000 to $100,000"
                DeallineDaate="September 30, 2022"
                Type="Opportunity"
            />

            <Cards

                Class="main_card"
                imgUrl="https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/t4ykdGYZ6ahNPWouzzMqUHUIIyzmLziu4kqBnXIN.jpg"
                title={<NavLink to='/opportunity/op/details' > Fast Forward Announces Accelerator For Tech Nonprofits </NavLink>}
                description="The Fast Forward is inviting applications for the tech and nonprofit worlds that provides training and resources applicable to the challenges only a tech nonprofit"
                PostDate="August 24, 2022"
                // Category="Grant"
                // DonorAgency="Bank of Scotland Foundation"
                // GrantSize=" $10,000 to $100,000"
                DeallineDaate="September 30, 2022"
                Type="Opportunity"
            />

            <Cards

                Class="main_card"
                imgUrl="https://s3.amazonaws.com/cdn.fundsforngospremium.com/img.Grants/thumb-600px/t4ykdGYZ6ahNPWouzzMqUHUIIyzmLziu4kqBnXIN.jpg"
                title={<NavLink to='/opportunity/op/sdfs' > Fast Forward Announces Accelerator</NavLink>}
                description="The Fast Forward is inviting applications for the tech and nonprofit worlds that provides training and resources applicable to the challenges only a tech nonprofit"
                PostDate="August 24, 2022"
                // Category="Grant"
                // DonorAgency="Bank of Scotland Foundation"
                // GrantSize=" $10,000 to $100,000"
                DeallineDaate="September 30, 2022"
                Type="Opportunity"
            />


        </>
    )
}

export default MainSection